import classnames from 'classnames';
import { ReactNode } from 'react';

import { BannerTheme } from 'components/ui/shared/notifications/banner';

import style from './simpleBanner.scss';

interface Props {
  /** The content to render */
  children: ReactNode;
  /** Any overriding class names */
  className?: string;
  /** The theme of the banner */
  theme?: BannerTheme;
}

const SimpleBanner = ({ children, className, theme = BannerTheme.SUCCESS }: Props) => (
  <div className={classnames(style.container, style[`container-theme-${theme}`], className)}>{children}</div>
);

export default SimpleBanner;
