import { useMemo } from 'react';
import classNames from 'classnames';

import Loading from 'components/ui/loading/loading';
import SlideOut from 'components/ui/slideOuts/slideOut';
import { BlueDriverSmogScanEvent, BlueDriverSmogScanEventResult } from 'store/shared/api/graph/interfaces/types';
import { SlideOutComponentProps } from 'components/sections/inventoryItem/details/slideOut/slideOut';
import { t } from 'utils/intlUtils';

import style from './smogSlideOut.scss';

interface Props extends SlideOutComponentProps {
  /** Whether the list is loading or not. */
  isLoading?: boolean;
  /** Whether the slide out is open or not. */
  isOpen?: boolean;
  /** Callback function triggered when slide out closes. */
  onClose?: () => void;
  /** The smog data from the scan event. */
  smogScanEvent: BlueDriverSmogScanEvent | undefined;
}

const SmogSlideOut = ({ isLoading, isOpen, onClose, smogScanEvent }: Props) => {
  const statusStyle = useMemo(
    () => `status-${smogScanEvent?.result && BlueDriverSmogScanEventResult[smogScanEvent?.result]?.toLowerCase()}`,
    [smogScanEvent?.result]
  );

  const scanDetails = useMemo(() => {
    if (!smogScanEvent || isLoading) {
      return null;
    }

    return (
      <div className={style.container}>
        <div className={style.header}>
          <div>{t('status')}</div>
          <div className={classNames(style.scanResult, style[statusStyle])} data-testid="scan-event-result">
            {smogScanEvent.result}
          </div>
        </div>
        <ul className={style.list}>
          <li className={classNames(style.listItem, style.listItemHeader)}>
            <div>{t('system_monitors')}</div>
            <div className={style.status}>{t('status')}</div>
          </li>
          {smogScanEvent.completeMonitors?.map((monitor) => (
            <li key={monitor} className={style.listItem} data-testid="complete-monitor">
              <div>{monitor}</div>
              <div>{t('complete')}</div>
            </li>
          ))}
          {smogScanEvent.incompleteMonitors?.map((monitor) => (
            <li key={monitor} className={style.listItem} data-testid="incomplete-monitor">
              <div>{monitor}</div>
              <div>{t('incomplete')}</div>
            </li>
          ))}
        </ul>
      </div>
    );
  }, [isLoading, statusStyle, smogScanEvent]);

  return (
    <SlideOut isOpen={isOpen} onClose={onClose} title={t('smog_readiness')}>
      <div className={style.scanDetails}>{scanDetails}</div>
      <Loading hasFullWidth isLoading={isLoading} isModalTransparent />
    </SlideOut>
  );
};

export default SmogSlideOut;
