import { Route } from 'store/routing/routes';

// Existing Routes that have yet to be tracked
export const missingPageViewRoutes = [
  Route.ADMIN_EVENTS_LANE_MANAGEMENT,
  Route.ADMIN_LIVE_LANES_AUCTIONEER,
  Route.ADMIN_LIVE_LANES_AUCTION_CLERK,
  Route.ADMIN_LIVE_LANES_MARQUEE,
  Route.BUY_COMPARABLES,
  Route.ERROR_404,
] as const;

// Redirect Routes
// High-level entry points for any given section. Should not send tracking page until child route is hit.
export const redirectRoutes = [
  Route.ADMIN,
  Route.AUTH,
  Route.BUY,
  Route.BUY_HOURLY_AUCTION,
  Route.FINISHED,
  Route.MY_BLOCK,
  Route.PENDING,
  Route.SELL,
  Route.TRANSPORT,
] as const;

export type MissingPageViewRoutes = (typeof missingPageViewRoutes)[number];

// Routes that redirect to other pages
export type RedirectRoutes = (typeof redirectRoutes)[number];

/**
 * Pages - Maps `Route` values to a digestible string that will be pushed to our analytics suite.
 *
 * TODO: Remove MissingPageViewRoutes once all routes are added
 */
export const PageView: Record<Exclude<Route, RedirectRoutes | MissingPageViewRoutes>, string> = {
  [Route.HOME]: 'home_dashboard',

  [Route.ACCOUNT]: 'account',
  [Route.ACCOUNT_COMPANIES]: 'account_companies',
  [Route.ACCOUNT_DELETE_ACCOUNT]: 'account_delete_account',

  [Route.ACKNOWLEDGEMENTS]: 'acknowledgements',

  [Route.ADMIN_ARBITRATIONS]: 'arbitrations',
  [Route.ADMIN_AUCTIONS]: 'admin_auctions',
  [Route.ADMIN_BROADCASTS]: 'admin_broadcasts',
  [Route.ADMIN_COMPANIES]: 'admin_companies',
  [Route.ADMIN_EVENTS]: 'admin_events',
  [Route.ADMIN_REPORTS]: 'reports',
  [Route.ADMIN_REPORT]: 'report',
  [Route.ADMIN_USERS]: 'admin_users',

  [Route.AUTH_CARFAX_USA]: 'auth_carfaxusa',
  [Route.AUTH_EMPLOYEE]: 'auth_employee',
  [Route.AUTH_FORGOT_PASSWORD]: 'auth_forgot_password',
  [Route.AUTH_LOGIN]: 'auth_login',
  [Route.AUTH_LOGOUT]: 'auth_logout',
  [Route.AUTH_PIPELINE]: 'auth_pipeline',
  [Route.AUTH_REGISTER]: 'auth_register',
  [Route.AUTH_REQUEST_INVITE]: 'auth_request_invite',
  [Route.AUTH_RESET_PASSWORD]: 'auth_reset_password',

  [Route.BUY_LIVE_AUCTION]: 'buy_live_auction',
  [Route.BUY_RUN_LIST]: 'buy_run_list',
  [Route.BUY_TIMED_AUCTION]: 'buy_timed_auction',
  [Route.BUY_BUY_NOW]: 'buy_buy_now',

  [Route.FINISHED_CANCELLED]: 'finished_cancelled',
  [Route.FINISHED_NO_SALE]: 'finished_no_sale',
  [Route.FINISHED_PURCHASED]: 'finished_purchased',
  [Route.FINISHED_SOLD]: 'finished_sold',

  [Route.MY_BLOCK_BUYER]: 'my_block_buyer',
  [Route.MY_BLOCK_SELLER]: 'my_block_seller',

  [Route.MY_MARKET_GUIDE]: 'my_market_guide',

  [Route.NOTIFICATION_SETTINGS]: 'notification_settings',

  [Route.PENDING_AWAITING_CHECKOUT]: 'pending_awaiting_checkout',
  [Route.PENDING_DELIVERY]: 'pending_pending_delivery',
  [Route.PENDING_IN_IF_BID]: 'pending_in_if_bid',

  [Route.SELL_PARKED]: 'sell_parked',

  [Route.TRANSPORT_CARRIERS]: 'transport_carriers',
  [Route.TRANSPORT_JOBS]: 'transport_transport_jobs',
};
