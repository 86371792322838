import AuctionItem from 'constants/auctionItem';
import BaseClass from 'components/ui/shared/base';
import ConfirmDialog from 'components/ui/shared/dialogs/confirmDialog';
import Select from 'forms/shared/select';
import SelectCompany from 'forms/shared/selectCompany';
import SelectUser from 'forms/shared/selectUser';
import TextArea from 'forms/shared/textArea';
import { ErrorMessages } from 'constants/errors';
import { MutationauctionItemRequestArbitrationArgs } from 'store/shared/api/graph/interfaces/types';
import { SelectOption } from 'utils/interfaces/SelectOption';
import { t } from 'utils/intlUtils';

import style from './dialog.scss';

interface Props {
  /** Auction item information. */
  auctionItem: AuctionItem;
  /** Error messages. */
  errorMessages: ErrorMessages | null;
  /** Whether the dialog is open or not. */
  isOpen: boolean;
  /** Whether is auction staff or not. */
  isStaffUser: boolean;
  /** Callback function to handle on submit event. */
  onSubmit: (shouldSubmit: boolean, options: Partial<MutationauctionItemRequestArbitrationArgs>) => void;
  /** Whether is progressing or not. */
  progress: boolean;
}

interface State {
  /** Company id. */
  companyId?: string;
  /** Request reason. */
  reason?: string;
  /** User id. */
  userId?: string;
}

class Dialog extends BaseClass<Props, State> {
  state = {
    companyId: undefined,
    reason: undefined,
    userId: undefined,
  };

  onCompanyChange = (company) => {
    this.setState({ companyId: company?.value, userId: undefined });
  };

  onUserChange = (user: SelectOption) => {
    this.setState({ userId: user?.value });
  };

  onReasonChange = (e) => {
    this.setState({ reason: e.target.value });
  };

  requestArbitration = (shouldSubmit) => {
    const { companyId, reason, userId } = this.state;
    const { onSubmit } = this.props;
    const options: Partial<MutationauctionItemRequestArbitrationArgs> = {
      consignerId: companyId,
      content: reason,
      userId,
    };

    onSubmit(shouldSubmit, options);
  };

  renderConsigners = () => {
    const { auctionItem, isStaffUser } = this.props;
    const { companyId } = this.state;

    const buyer = auctionItem?.buyer?.company;
    const seller = auctionItem?.inventoryItem?.company;
    const consignersDropdownData = [
      { value: buyer?.id, label: buyer?.name },
      { value: seller?.id, label: seller?.name },
    ];

    return (
      <>
        {isStaffUser ? (
          <Select
            className={style.consigners}
            onChange={this.onCompanyChange}
            options={consignersDropdownData}
            placeholder={t('select_company')}
            theme="green"
          />
        ) : (
          <SelectCompany onChange={this.onCompanyChange} placeholder={t('select_company')} />
        )}
        <SelectUser
          companyId={companyId}
          isDisabled={!companyId}
          onChange={this.onUserChange}
          placeholder={t('select_user')}
        />
      </>
    );
  };

  render() {
    const { reason, userId } = this.state;
    const { errorMessages, isOpen, isStaffUser, progress } = this.props;

    const actionable = isStaffUser ? !!(userId && reason) : !!reason;
    const hasError = errorMessages && errorMessages.length > 0;

    return (
      <ConfirmDialog
        actionable={actionable}
        actionLabel={t('submit')}
        actionProgress={progress}
        isOpen={isOpen}
        onConfirm={this.requestArbitration}
        theme="red"
        title={t('arbitrate')}
      >
        <p className={style.instructions}>{t('arbitration_dialog_message')}</p>
        {this.renderConsigners()}
        <TextArea
          className={style.reason}
          onChange={this.onReasonChange}
          placeholder={t('enter_reason')}
          theme={hasError ? 'error' : undefined}
        />
        {errorMessages &&
          errorMessages.map((errorMessage, index) => (
            <p key={`error${index}`} className={style.errorMessage}>
              {errorMessage?.message}
            </p>
          ))}
      </ConfirmDialog>
    );
  }
}

export default Dialog;
