import AuctionItem from 'constants/auctionItem';
import BaseClass from 'components/ui/shared/base';
import ConfirmDialog from 'components/ui/shared/dialogs/confirmDialog';
import Select from 'forms/shared/select';
import { ErrorMessages } from 'constants/errors';
import { MutationauctionItemSetAsIsArgs } from 'store/shared/api/graph/interfaces/types';
import { t } from 'utils/intlUtils';

import style from './dialog.scss';

interface Props {
  /** Auction item to change buyer. */
  auctionItem: AuctionItem;
  /** Error messages. */
  errorMessages?: ErrorMessages;
  /** Whether the dialog is open or not. */
  isOpen: boolean;
  /** Whether the dialog is submitting or not. */
  isSubmitting?: boolean;
  /** Callback function that handles on submit event. */
  onSubmit: (shouldSubmit: boolean, options: MutationauctionItemSetAsIsArgs | undefined) => void;
}

interface State {
  /** The selected asIs value. */
  asIs: boolean | undefined;
}

class Dialog extends BaseClass<Props, State> {
  constructor(props) {
    super(props);

    this.state = { asIs: undefined };
  }

  onSubmit = (shouldSubmit) => {
    const {
      auctionItem: { id },
      onSubmit,
    } = this.props;
    const { asIs } = this.state;

    if (!shouldSubmit || asIs === undefined) {
      onSubmit(false, undefined);
      return;
    }

    const options: MutationauctionItemSetAsIsArgs = { auctionItemId: id, asIs };
    onSubmit(shouldSubmit, options);
  };

  onAsIsChange = ({ value: asIs }) => {
    this.setState({ asIs });
  };

  render() {
    const { asIs } = this.state;
    const { auctionItem, isOpen, errorMessages, isSubmitting } = this.props;
    const {
      inventoryItem: { asIsCause },
    } = auctionItem;
    const asIsOptions = [
      { value: true, label: t('set_as_is') },
      { value: false, label: t('remove_as_is') },
    ];

    return (
      <ConfirmDialog
        actionable={asIs !== undefined}
        actionLabel={t('confirm')}
        actionProgress={isSubmitting}
        isOpen={isOpen}
        onConfirm={this.onSubmit}
        theme="blue"
        title={t('as_is').toUpperCase()}
      >
        <div className={style.instructions}>
          {!asIsCause && <p>{t('unit_does_not_meet_as_is_policy_question')}</p>}
          {asIsCause && <p>{t('unit_falls_under_as_is_policy_question')}</p>}
          {asIsCause && <p>{asIsCause}</p>}
        </div>
        <Select className={style.select} id="setAsIsSelect" onChange={this.onAsIsChange} options={asIsOptions} />
        {errorMessages &&
          errorMessages.map((errorMessage, index) => (
            <p key={`error${index}`} className={style.errorMessage}>
              {errorMessage.message}
            </p>
          ))}
      </ConfirmDialog>
    );
  }
}

export default Dialog;
