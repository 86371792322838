import { CSSProperties, ReactNode } from 'react';
import classnames from 'classnames';

import style from './button.scss';

export type ButtonTheme =
  | 'none'
  | 'black'
  | 'black-attached'
  | 'black-outline'
  | 'blue'
  | 'blue-attached'
  | 'blue-outline'
  | 'gray-outline'
  | 'gray-attached'
  | 'green'
  | 'green-attached'
  | 'green-outline'
  | 'red'
  | 'red-attached'
  | 'red-outline'
  | 'yellow'
  | 'yellow-attached'
  | 'yellow-outline';

// ================================================

export interface ButtonProps {
  /** Label for accessibility. */
  ariaLabel?: string;
  /** Element reference for accessibility. */
  ariaLabelledBy?: string;
  /** Child elements to be rendered. */
  children?: ReactNode;
  /** CSS styling to overwrite default button style. */
  className?: string | string[];
  /** Set the html `data-testid` attribute. */
  dataTestId?: string;
  /** True when button is disabled. */
  disabled?: boolean;
  /** True when element has AriaLiveText for accessibility. */
  hasAriaLiveText?: boolean;
  /** The id of the button. */
  id?: string;
  /** Set the html `lang` attribute. */
  lang?: string;
  /** Function invoked on button click. */
  onClick?: (event) => void;
  /** Function invoked on button focus. */
  onFocus?: (event) => void;
  /** Function invoked on button mouse leave. */
  onMouseLeave?: (event) => void;
  /** Function invoked on button mouse over. */
  onMouseOver?: (event) => void;
  /** References `<button>` in DOM - for use in parent component. */
  reference?: (ref: HTMLButtonElement) => void;
  /** Set the html `role` attribute. */
  role?: string;
  /** Visually simulate the interactive states of the button. */
  state?: 'active' | 'hover';
  /** Button's inline style. */
  style?: CSSProperties;
  /** True when button is submitting. */
  submitting?: boolean;
  /** The button theme. */
  theme?: ButtonTheme;
  /** Set the html `title` attribute. */
  title?: string;
  /** Set the html `type` attribute. Defaults to `button`, other common option is `submit` */
  type?: 'submit' | 'reset' | 'button';
}

const Button = ({
  ariaLabel,
  ariaLabelledBy,
  children,
  className,
  dataTestId,
  disabled,
  hasAriaLiveText,
  id,
  lang,
  onClick,
  onFocus,
  onMouseLeave,
  onMouseOver,
  reference,
  role,
  state,
  style: inlineStyle,
  submitting,
  theme = 'green-outline',
  title,
  type = 'button',
  ...props
}: ButtonProps) => {
  const base = theme !== 'none';
  const outline = theme?.includes('outline');
  const attached = theme?.includes('attached');

  const classes = classnames(
    'button',
    style.button,
    disabled && style.disabled,
    base && style['theme-base'],
    attached && style.attached,
    outline && style.outline,
    theme && style[`theme-${theme}`],
    state && style[state],
    className
  );

  return (
    <button
      ref={reference}
      aria-label={ariaLabel}
      aria-labelledby={ariaLabelledBy}
      aria-live={hasAriaLiveText ? 'assertive' : undefined}
      className={classes}
      data-testid={dataTestId}
      disabled={submitting || disabled}
      id={id}
      lang={lang}
      onClick={(e) => {
        e.currentTarget.blur();

        if (onClick) {
          onClick(e);
        }
      }}
      onFocus={(e) => onFocus && onFocus(e)}
      onMouseLeave={(e) => onMouseLeave && onMouseLeave(e)}
      onMouseOver={(e) => onMouseOver && onMouseOver(e)}
      role={hasAriaLiveText ? 'alert' : role}
      style={inlineStyle}
      title={title}
      type={type}
      {...props}
    >
      {children}
    </button>
  );
};

export default Button;
