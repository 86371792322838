/**
 * Used in consigners & users(list/details) view in admin.
 * Requires the `locationFragment`.
 *
 * @param withSavedFilterDetails
 * @returns {string}
 */
export const user = ({ withSavedFilterDetails = false } = {}) => `
  id
  firstName
  lastName
  title
  photoUrl(width:100, height:100)
  created
  updated
  lastLoggedIn
  locked
  status
  source
  channels
  email
  employee
  notes {
    id
    content
    created
    createdBy {
      firstName
      lastName
      photoUrl(width:150, height:150)
      lastLoggedIn
      id
    }
  }
  cellPhone
  secondaryPhone
  auctionAccessId
  auctionAccessIssueLogs {
    id
    effectiveDate
    issueName
    resolvedDate
    status
    visibility
  }
  auctionAccessLastVerified
  auctionAccessStatus
  omvicRegNumber
  omvicRegExpiryDate
  locale
  timeZone
  distanceUnit
  preferredLocation {
    ...location
  }
  notificationDevices {
    id
    active
    deviceType
    deviceName
    created
    appVersion
  }
  savedFilters {
    id
    name
    description
    ${
      withSavedFilterDetails
        ? `
    ... on AuctionItemSavedFilter {
      formats
      auctionLocationName
      auctionTitle
      distance
      region
      mileageGTE
      mileageLTE
      yearGTE
      yearLTE
      makes
      models
      subModels
      bodyType
      driveTrain
      transmission
      fuelType
      exteriorColor
      options
    }
    `
        : ''
    }
  }
`;
