import classnames from 'classnames';
import { MouseEventHandler, useCallback } from 'react';

import editGlyph from 'glyphs/edit.svg';

import Button from 'components/ui/shared/button';
import CarfaxUSButton from 'components/sections/inventoryItem/details/conditionReport/carfaxUSButton';
import Sprite from 'components/ui/shared/sprite';
import User from 'constants/user';
import VehicleConditionReport from 'constants/vehicleConditionReport';
import carfaxImageCA from 'static/images/carfax-ca.png';
import carfaxImageUS from 'static/images/carfax-us.png';
import { Chevron } from 'components/ui/shared/chevrons';
import { VehicleFeature } from 'components/sections/inventoryItem/details/vehicleDetails';
import { isAuctionStaff } from 'utils/userUtils';
import { t } from 'utils/intlUtils';
import { vehicleFeatures } from 'components/sections/inventoryItem/details/inventoryItemDetails';

import style from './carfaxButton.scss';

export enum CarfaxButtonVariant {
  DEFAULT = 'DEFAULT',
  MY_MARKET_GUIDE = 'MY_MARKET_GUIDE',
}

interface Props {
  /** Country code. */
  countryCode?: string;
  /** Callback function to open add/modify modal. */
  handleOpenAddModifyModal?: (feature: VehicleFeature) => void;
  /** True when inventory item details are editable. */
  isUpdatable?: boolean;
  /** Function invoked on carfax button click. */
  onClick?: MouseEventHandler<HTMLButtonElement | HTMLAnchorElement>;
  /** The current user. */
  user: User;
  /** The inventory condition report */
  vehicleConditionReport?: VehicleConditionReport;
  /** The inventory vin number */
  vin?: string;
  /** Different variants of the Carfax button */
  variant?: CarfaxButtonVariant;
}

/**
 * TODO: This component can be deleted when the 'carfax-mvda-support' is deleted.
 */
const CarfaxButtonLegacy = ({
  countryCode,
  handleOpenAddModifyModal,
  isUpdatable = false,
  onClick,
  user,
  variant = CarfaxButtonVariant.DEFAULT,
  vehicleConditionReport,
  vin,
}: Props) => {
  const { carfax } = vehicleFeatures;
  const isStaffUser = isAuctionStaff(user);

  const isCA = countryCode === 'CA';
  const isUS = countryCode === 'US';
  const carfaxClaimAmount = vehicleConditionReport?.carfaxCanadaClaimAmount;
  const carfaxClaimAmountFormatted = carfaxClaimAmount?.formattedAmountRounded;
  const carfaxImage = isCA && variant !== CarfaxButtonVariant.MY_MARKET_GUIDE ? carfaxImageCA : carfaxImageUS;
  const carfaxUrl = vehicleConditionReport?.carfaxCanadaReportUrl;
  const hasCarfaxClaimAmount = !!carfaxClaimAmount && carfaxClaimAmount.amount > 0;

  const buttonClasses = classnames(
    style.crButton,
    isCA && isUpdatable && style.crButtonUpdatable,
    hasCarfaxClaimAmount && style.crButtonRd,
    isStaffUser && style.centeredArrow
  );

  const linkClasses = classnames(
    style.crButton,
    hasCarfaxClaimAmount && style.crButtonRd,
    isCA && (isUpdatable || !carfaxUrl) && style.disabled,
    isStaffUser && style.centeredArrow
  );

  const onCarfaxCaButtonClick = useCallback(
    (event) => {
      handleOpenAddModifyModal?.(carfax);
      onClick?.(event);
    },
    [carfax, handleOpenAddModifyModal, onClick]
  );

  if (variant === CarfaxButtonVariant.MY_MARKET_GUIDE) {
    return (
      <div className={style.mmgCarfaxLegacy} data-testid="carfax-marketguide">
        <div className={style.mmgAmount}>{carfaxClaimAmountFormatted || '$ -'}</div>
        <img alt={t('carfax')} className={style.mmgCarfaxImage} src={carfaxImage} />
      </div>
    );
  }

  if (isCA && !isUpdatable) {
    return (
      <a
        className={linkClasses}
        data-testid="carfax-ca-link"
        href={carfaxUrl || undefined}
        onClick={onClick}
        rel="noopener noreferrer"
        target="_blank"
      >
        <div>
          <span className={classnames(style.crButtonText, style.buttonText)}>{carfaxClaimAmountFormatted || '-'}</span>
          <img alt={t('carfax')} className={style.carfax} src={carfaxImage} />
        </div>
        <Chevron />
      </a>
    );
  }

  if (isUS) {
    return <CarfaxUSButton className={style.carfaxUSButton} onClick={onClick} vin={vin || undefined} />;
  }

  return (
    <Button className={buttonClasses} data-testid="carfax-ca-button" onClick={onCarfaxCaButtonClick} theme="none">
      <div>
        {isCA && isUpdatable && <Sprite className={style.crEditGlyph} glyph={editGlyph} />}
        {isCA && (
          <span className={classnames(style.crButtonText, style.text)}>{carfaxClaimAmountFormatted || '-'}</span>
        )}
        <img alt={t('carfax')} className={style.carfax} src={carfaxImage} />
      </div>
      <Chevron className={style.chevron} />
    </Button>
  );
};

export default CarfaxButtonLegacy;
