import { useCallback, useState } from 'react';

import Logger from 'logging/Logger';
import OAuthDialog from 'components/ui/shared/dialogs/oAuthDialog';
import { RouterProps, withRouter } from 'constants/reactRouter';
import { Spinner } from 'components/ui/loading/loading';
import { UserAction } from 'logging/analytics/events/userActions';
import { WhiteAuthButton } from 'components/sections/auth/auth';
import { authenticateEmployee } from 'store/auth/authApi';
import { cacheAuth } from 'utils/authUtils';
import { t } from 'utils/intlUtils';
import { useGlobalDialog } from 'contexts/globalDialogContext';
import { getErrors } from 'utils/apiUtils';

import style from './loginWithEdgePipeline.scss';

interface Props extends RouterProps {
  /** Callback function to try login. */
  tryLogin: () => Promise<void>;
}

const LoginWithGoogleAuth = ({ tryLogin, router }: Props) => {
  const { setConfig } = useGlobalDialog();
  const [isFetchingOAuthCode, setIsFetchingOAuthCode] = useState<boolean>(false);
  const clientId = process.env.REACT_APP_OAUTH_GOOGLE_CLIENT_ID;
  const redirectUri = process.env.REACT_APP_OAUTH_GOOGLE_REDIRECT_URI;
  const title = t('employee_sign_in');
  const url = `${process.env.REACT_APP_OAUTH_GOOGLE_URI}?client_id=${clientId}&redirect_uri=${redirectUri}&response_type=code&scope=openid%20email%20profile`;

  /**
   * Authenticate user
   */
  const onRequestGoogleAuthAccess = useCallback(
    (code, windowRef) => {
      setIsFetchingOAuthCode(true);

      authenticateEmployee({ code })
        ?.then((response: any) => {
          cacheAuth(response?.data?.data?.employeeAuthenticate?.authenticationToken);
          windowRef.current?.close?.();
          tryLogin()?.then(() => router?.replace('/'));
        })
        ?.catch((error) => setConfig({ errorsOverride: getErrors(error) }))
        ?.finally(() => setIsFetchingOAuthCode(false));
    },
    [router, setConfig, tryLogin]
  );

  /**
   * Track user action on button click
   */
  const onButtonClick = useCallback(() => {
    Logger.trackUserAction(UserAction.EMPLOYEE_LOGIN_CLICK);
  }, []);

  return (
    <div className={style.authLink}>
      <OAuthDialog height={600} onCode={onRequestGoogleAuthAccess} title={title} url={url} width={700}>
        <WhiteAuthButton className={style.partnerLoginButtonLink} onClick={onButtonClick} title={title}>
          {isFetchingOAuthCode ? <Spinner /> : title}
        </WhiteAuthButton>
      </OAuthDialog>
    </div>
  );
};

export default withRouter(LoginWithGoogleAuth);
