import { ChangeEvent, FormEvent, useCallback, useState } from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { Link } from 'react-router';
import { replace } from 'react-router-redux';

import hidePassGlyph from 'glyphs/eye-hide.svg';
import poweredByEBlockGlyph from 'glyphs/powered-by-eblock.svg';
import showPassGlyph from 'glyphs/eye-show.svg';

import Button from 'components/ui/shared/button';
import Logger from 'logging/Logger';
import LoginWithGoogleAuth from 'components/sections/auth/register/loginWithGoogleAuth';
import Sprite from 'components/ui/shared/sprite';
import { AppDispatch, AppState } from 'store/configureStore';
import { EXPIRED_PASSWORD_PARAM } from 'components/sections/auth/resetPassword';
import { ErrorMessages } from 'constants/errors';
import { FormErrors } from 'layouts/formLayouts/formDialogLayouts';
import { Route } from 'store/routing/routes';
import { Spinner } from 'components/ui/loading/loading';
import { SubmitButton } from 'components/sections/auth/auth';
import { UserAction } from 'logging/analytics/events/userActions';
import { clearRoutingTarget } from 'store/routing/routingActions';
import { getErrors } from 'utils/apiUtils';
import { getUrlParamsToQueryString } from 'utils/urlUtils';
import { joinStrings } from 'utils/stringUtils';
import { processRefreshFeatureFlags } from 'store/user/userActions';
import { processTryLogin, processUserLogin } from 'store/auth/authActions';
import { t } from 'utils/intlUtils';
import { useMountEffect } from 'hooks/useMountEffect';

import style from './auth.scss';

export enum LoginError {
  EXPIRED = 'expired',
  LOCKED = 'locked',
}

const stateConnect = (state: AppState) => ({
  /** Authorization information. */
  auth: state.app.auth,
  /** Routing target information. */
  routingTarget: state.app.routes.routingTarget?.toJS(),
});

const dispatchConnect = (dispatch: AppDispatch) => ({
  /** Callback function to clear routing target. */
  clearRoutingTarget: () => dispatch(clearRoutingTarget()),
  /** Callback function to get feature flags. */
  getFeatureFlags: () => processRefreshFeatureFlags(dispatch),
  /** Callback function to log in the user. */
  login: (loginForm, redirect) => processUserLogin(dispatch, loginForm, () => redirect()),
  /** Callback function to replace history location. */
  replace: (location) => dispatch(replace(location)),
  /** Callback function to try login. */
  tryLogin: () => processTryLogin(dispatch),
});

const connector = connect(stateConnect, dispatchConnect);

type Props = ConnectedProps<typeof connector>;

const Login = ({ auth, getFeatureFlags, routingTarget, login, tryLogin, ...props }: Props) => {
  const [email, setEmail] = useState<string>();
  const [errorMessages, setErrorMessages] = useState<ErrorMessages>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isShowPassword, setShowPassword] = useState<boolean>(false);
  const [password, setPassword] = useState<string>();
  const { isAuctionAccessMode } = auth;

  /**
   * onMount - Get feature flags
   */
  useMountEffect(() => {
    getFeatureFlags();
  });

  /**
   * Handle redirect
   */
  const handleRedirect = useCallback(() => {
    if (routingTarget) {
      props.replace({
        pathname: routingTarget.pathname,
        query: routingTarget.query,
      });
      props.clearRoutingTarget?.();
    } else {
      props.replace(Route.HOME);
    }
  }, [props, routingTarget]);

  /**
   * Login user
   */
  const onLogin = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      Logger.trackUserAction(UserAction.SIGN_IN_CLICK);

      const trimmedEmail = email?.trim();
      const trimmedPassword = password?.trim();

      if (!trimmedEmail?.length && !trimmedPassword?.length) {
        return setErrorMessages([{ message: t('username_password_required') }]);
      }

      setErrorMessages(undefined);
      setIsLoading(true);
      return login({ email: trimmedEmail, password: trimmedPassword }, handleRedirect).catch((err) => {
        const errors = getErrors(err);
        const error = errors?.[0];
        const errorResetToken = error?.extensions?.['password-reset-token'];

        if (error?.name === LoginError.EXPIRED && !!errorResetToken) {
          // The user's password is expired; redirect to reset password flow
          const routeBasePath = Route.AUTH_RESET_PASSWORD.split(':token')?.[0];
          const redirectPath = joinStrings([routeBasePath, errorResetToken, `?${EXPIRED_PASSWORD_PARAM}`], '');
          props.replace(redirectPath);
          return;
        }

        if (error?.name === LoginError.LOCKED) {
          // The user's account is locked; redirect to self-serve forgot password flow
          const urlParams = `email=${trimmedEmail}&errorName=${error.name}&errorMessage=${error.message}`;
          props.replace(`${Route.AUTH_FORGOT_PASSWORD}?${urlParams}`);
          return;
        }

        setErrorMessages(errors);
        setIsLoading(false);
      });
    },
    [email, handleRedirect, login, password, props]
  );

  /**
   * Handle email change
   */
  const onEmailChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  }, []);

  /**
   * Handle password change
   */
  const onPasswordChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  }, []);

  /**
   * Toggles the show/hide password state
   */
  const onShowHidePassword = useCallback(() => {
    setShowPassword(!isShowPassword);
  }, [isShowPassword]);

  return (
    <form className={style.authForm} onSubmit={onLogin}>
      <h2 className={style.header}>{t('login')}</h2>
      {errorMessages && (
        <FormErrors
          className={style.formErrors}
          dataTestId="form-errors"
          errorMessages={errorMessages}
          isInteractiveText
        />
      )}
      <div className={style.inputGroup}>
        <input
          autoFocus
          className={style.input}
          data-testid="email"
          name="email"
          onChange={onEmailChange}
          placeholder={t('email')}
        />
      </div>
      <div className={style.inputGroup}>
        <input
          className={style.input}
          data-testid="password"
          name="password"
          onChange={onPasswordChange}
          placeholder={t('password')}
          type={isShowPassword ? 'text' : 'password'}
        />
        <Button
          ariaLabel={t(isShowPassword ? 'hide_password' : 'show_password')}
          className={style.showHidePass}
          onClick={onShowHidePassword}
          theme="none"
        >
          <Sprite glyph={isShowPassword ? hidePassGlyph : showPassGlyph} />
        </Button>
      </div>
      <SubmitButton dataTestId="submit" disabled={isLoading}>
        {!isLoading ? t('sign_in') : <Spinner className={style.loadingSpinner} theme="white" />}
      </SubmitButton>
      <div className={style.altLinks}>
        {isAuctionAccessMode && (
          <>
            <Link className={style.altLink} to={`${Route.AUTH_REGISTER}?s=0&${getUrlParamsToQueryString()}`}>
              {t('go_back')}
            </Link>
            {' | '}
          </>
        )}
        <>
          <LoginWithGoogleAuth tryLogin={tryLogin} />
          {' | '}
        </>
        <Link className={style.altLink} to={`${Route.AUTH_FORGOT_PASSWORD}?${getUrlParamsToQueryString()}`}>
          {t('forgot_password_question')}
        </Link>
      </div>
      {process.env.PRIVATE_LABEL && <Sprite className={style.poweredBy} glyph={poweredByEBlockGlyph} />}
    </form>
  );
};

export default connector(Login);
