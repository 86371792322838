import { MouseEvent } from 'react';
import { connect, ConnectedProps } from 'react-redux';

import editGlyph from 'glyphs/edit.svg';

import BaseClass from 'components/ui/shared/base';
import Button from 'components/ui/shared/button';
import ConfirmDialog from 'components/ui/shared/dialogs/confirmDialog';
import SelectLocation from 'components/sections/inventoryItem/addModify/selectLocation/selectLocation';
import Sprite from 'components/ui/shared/sprite';
import { AppDispatch, AppState } from 'store/configureStore';
import { ErrorMessages } from 'constants/errors';
import {
  MutationinventoryItemChangeLocationArgs,
  QuerylocationConnectionArgs,
} from 'store/shared/api/graph/interfaces/types';
import { getErrors } from 'utils/apiUtils';
import {
  processGetConsignerLocations,
  processUpdateInventoryItemLocations,
} from 'store/inventoryItemDetails/inventoryItemDetailsActions';
import { t } from 'utils/intlUtils';

import style from './changeLocation.scss';

const stateConnect = (state: AppState) => ({
  /** The company location options. */
  consignerLocations: state.app.inventoryItemDetails.consignerLocations.toJS(),
});

const dispatchConnect = (dispatch: AppDispatch) => ({
  /** Dispatch function to get location options. */
  getLocations: (options: Pick<QuerylocationConnectionArgs, 'consignerId'>) =>
    processGetConsignerLocations(options, dispatch),
  /** Dispatch function to update the selected locations. */
  updateLocations: (options: MutationinventoryItemChangeLocationArgs & { type: string }) =>
    processUpdateInventoryItemLocations(options, dispatch),
});

const connector = connect(stateConnect, dispatchConnect);

interface Props extends ConnectedProps<typeof connector> {
  /** The id of the company. */
  consignerId: string | undefined;
  /** The id of the inventory item. */
  inventoryItemId: string;
  /** The id of the current location. */
  locationId?: string;
  /** Function invoked on confirm button click. */
  onConfirmClick?: (event?: MouseEvent<HTMLButtonElement>) => void;
  /** Function invoked on edit button click. */
  onEditClick?: (event?: MouseEvent<HTMLButtonElement>) => void;
  /** The id of the pickup location. */
  pickupLocationId?: string;
  /** Whether the item is an inventory item or auction item.*/
  type: 'inventoryItem' | 'auctionItem';
}

interface State {
  /** Validation errors. */
  errorMessages: ErrorMessages;
  /** True when the compound select option is enabled. */
  isCompoundEnabled: boolean;
  /** True when the dialog is open. */
  isOpen: boolean;
  /** The id of the selected location. */
  locationId: string | undefined;
  /** The id of the pickup location. */
  pickupLocationId: string | undefined;
  /** True when the form is submitting. */
  isSubmitting: boolean;
}

class ChangeLocation extends BaseClass<Props, State> {
  static defaultProps = {
    type: 'inventoryItem',
  } as Props;

  constructor(props) {
    super(props);

    this.state = {
      errorMessages: [],
      isCompoundEnabled: false,
      isOpen: false,
      isSubmitting: false,
      locationId: undefined,
      pickupLocationId: undefined,
    };
  }

  onOpen = () => {
    const { getLocations, consignerId, locationId, pickupLocationId } = this.props;
    getLocations({ consignerId: consignerId ? [consignerId] : undefined })
      .then(() => this.setState({ isOpen: true }))
      .catch((error) => {
        const errorMessages = getErrors(error);
        this.setState({ errorMessages, isOpen: true });
      });

    this.setState({
      isCompoundEnabled: !!pickupLocationId && pickupLocationId !== locationId,
      locationId,
      pickupLocationId,
    });
  };

  onClose = () => {
    this.setState({
      errorMessages: [],
      isCompoundEnabled: false,
      isOpen: false,
      isSubmitting: false,
      locationId: undefined,
      pickupLocationId: undefined,
    });
  };

  onConfirm = (shouldSubmit) => {
    if (shouldSubmit) {
      const { updateLocations, inventoryItemId, type } = this.props;
      const { locationId, pickupLocationId } = this.state;
      const options: MutationinventoryItemChangeLocationArgs & { type: string } = {
        inventoryItemId,
        locationId,
        pickupLocationId,
        type,
      };

      this.props.onConfirmClick?.();
      this.setState({ isSubmitting: true });
      updateLocations(options)
        .then(() => {
          this.setState({ isOpen: false, isSubmitting: false, isCompoundEnabled: false });
        })
        .catch((error) => {
          const errorMessages = getErrors(error).map((err) => err.message);
          this.setState({ errorMessages, isSubmitting: false });
        });
    }
  };

  render() {
    const { isOpen, isCompoundEnabled, locationId, pickupLocationId, isSubmitting, errorMessages } = this.state;
    const { consignerLocations, onEditClick } = this.props;

    return [
      <Button
        key="edit"
        className={style.button}
        dataTestId="edit-location-button"
        onClick={(event) => {
          onEditClick?.(event);
          this.onOpen();
        }}
        theme="none"
      >
        <Sprite glyph={editGlyph} />
      </Button>,
      <ConfirmDialog
        key="dialog"
        actionLabel={t('save')}
        actionProgress={isSubmitting}
        className={style.dialog}
        isOpen={isOpen}
        onClose={this.onClose}
        onConfirm={this.onConfirm}
        theme="green"
        title={t('change_location')}
      >
        <SelectLocation
          hasError={false}
          isCompoundEnabled={isCompoundEnabled}
          isDialogTheme
          isSmallTheme
          locationId={locationId}
          locations={consignerLocations}
          onChange={(loc, pickupLoc, compoundEnabled) => {
            this.setState({
              locationId: loc || undefined,
              pickupLocationId: pickupLoc || undefined,
              isCompoundEnabled: !!compoundEnabled,
            });
          }}
          pickupLocationId={pickupLocationId}
        />
        {errorMessages &&
          errorMessages.map((errorMessage, index) => (
            <p key={`error${index}`} className={style.errorMessage}>
              {errorMessage.message}
            </p>
          ))}
      </ConfirmDialog>,
    ];
  }
}

export default connector(ChangeLocation);
