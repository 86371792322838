// extracted by mini-css-extract-plugin
export default {"colorBlack":"#1d2126","colorBlackTransparent":"#1d212680","colorBlue":"#378ccb","colorBlueDark":"#0c65a7","colorBlueLighter":"#ebf4fa","colorGrayDark":"#5b656d","colorGrayDarker":"#313d48","colorGrayDarkest":"#24292f","colorGrayLight":"#adb2b6","colorGrayLighter":"#d6d8da","colorGrayLightest":"#ebeced","colorGreen":"#26b899","colorGreenLightest":"#e9f8f5","colorRed":"#e76f67","colorRedLightest":"#fdf1f0","colorText":"#24292f","colorWhite":"#fff","colorYellow":"#f6b551","fontWeightThin":"200","fontWeightRegular":"400","fontWeightSemiBold":"600","fontWeightBold":"700","fontSize10":".625rem","zIndexNeg1":"-1","zIndex0":"0","zIndex1":"1","zIndex2":"2","zIndex3":"3","zIndex4":"4","zIndex5":"5","container":"container_fc4b283","container-theme-success":"container-theme-success_afea69a","container-theme-warning":"container-theme-warning_cac7b29","container-theme-critical":"container-theme-critical_ce6a30b","icon":"icon_feb4657","icon-theme-default":"icon-theme-default_d9ad128","icon-theme-success":"icon-theme-success_bbcb043","icon-theme-warning":"icon-theme-warning_e2aa9d2","icon-theme-critical":"icon-theme-critical_d861218","title":"title_fb24b15","content":"content_b198c6a","closeButton":"closeButton_bff8840","closeIcon":"closeIcon_fabe30e"};
    if(module.hot) {
      (function() {
        var localsJsonString = "{\"colorBlack\":\"#1d2126\",\"colorBlackTransparent\":\"#1d212680\",\"colorBlue\":\"#378ccb\",\"colorBlueDark\":\"#0c65a7\",\"colorBlueLighter\":\"#ebf4fa\",\"colorGrayDark\":\"#5b656d\",\"colorGrayDarker\":\"#313d48\",\"colorGrayDarkest\":\"#24292f\",\"colorGrayLight\":\"#adb2b6\",\"colorGrayLighter\":\"#d6d8da\",\"colorGrayLightest\":\"#ebeced\",\"colorGreen\":\"#26b899\",\"colorGreenLightest\":\"#e9f8f5\",\"colorRed\":\"#e76f67\",\"colorRedLightest\":\"#fdf1f0\",\"colorText\":\"#24292f\",\"colorWhite\":\"#fff\",\"colorYellow\":\"#f6b551\",\"fontWeightThin\":\"200\",\"fontWeightRegular\":\"400\",\"fontWeightSemiBold\":\"600\",\"fontWeightBold\":\"700\",\"fontSize10\":\".625rem\",\"zIndexNeg1\":\"-1\",\"zIndex0\":\"0\",\"zIndex1\":\"1\",\"zIndex2\":\"2\",\"zIndex3\":\"3\",\"zIndex4\":\"4\",\"zIndex5\":\"5\",\"container\":\"container_fc4b283\",\"container-theme-success\":\"container-theme-success_afea69a\",\"container-theme-warning\":\"container-theme-warning_cac7b29\",\"container-theme-critical\":\"container-theme-critical_ce6a30b\",\"icon\":\"icon_feb4657\",\"icon-theme-default\":\"icon-theme-default_d9ad128\",\"icon-theme-success\":\"icon-theme-success_bbcb043\",\"icon-theme-warning\":\"icon-theme-warning_e2aa9d2\",\"icon-theme-critical\":\"icon-theme-critical_d861218\",\"title\":\"title_fb24b15\",\"content\":\"content_b198c6a\",\"closeButton\":\"closeButton_bff8840\",\"closeIcon\":\"closeIcon_fabe30e\"}";
        // 1731733616899
        var cssReload = require("../../../../../../node_modules/mini-css-extract-plugin/dist/hmr/hotModuleReplacement.js")(module.id, {});
        // only invalidate when locals change
        if (
          module.hot.data &&
          module.hot.data.value &&
          module.hot.data.value !== localsJsonString
        ) {
          module.hot.invalidate();
        } else {
          module.hot.accept();
        }
        module.hot.dispose(function(data) {
          data.value = localsJsonString;
          cssReload();
        });
      })();
    }
  