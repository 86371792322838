import classnames from 'classnames';
import { MouseEvent, useCallback } from 'react';

import AutoCheckButton from 'components/sections/inventoryItem/details/conditionReport/autoCheckButton';
import AutoGradeScoreButton from 'components/sections/inventoryItem/details/conditionReport/autoGradeScoreButton';
import BlueDriverScanData from 'components/sections/inventoryItem/details/conditionReport/blueDriverScanData/blueDriverScanData';
import CarfaxButton from 'components/sections/inventoryItem/details/conditionReport/carfaxButton';
import CarfaxButtonLegacy from 'components/sections/inventoryItem/details/conditionReport/carfaxButtonLegacy';
import DamagePhotosButton from 'components/sections/inventoryItem/details/conditionReport/damagePhotosButton';
import InventoryItem from 'constants/inventoryItem';
import NonVerifiedTireButton from 'components/sections/inventoryItem/details/conditionReport/nonVerifiedTireButton';
import NotificationBanner from 'components/ui/shared/notifications/notificationBanner';
import PaintButton from 'components/sections/inventoryItem/details/conditionReport/paintButton';
import PaintConditionDetails from 'components/sections/inventoryItem/details/conditionReport/paintConditionDetails';
import ScoreButton from 'components/sections/inventoryItem/details/conditionReport/scoreButton';
import ScoreExtensiveButton from 'components/sections/inventoryItem/details/conditionReport/scoreExtensiveButton';
import ScoresRow from 'components/sections/inventoryItem/details/conditionReport/scoresRow';
import TireDetails from 'components/sections/inventoryItem/details/conditionReport/tireDetails';
import User from 'constants/user';
import VerifiedTireButton from 'components/sections/inventoryItem/details/conditionReport/verifiedTireButton';
import { FeatureFlag } from 'constants/featureFlags';
import { LooseObject } from 'constants/objects';
import { UserAction } from 'logging/analytics/events/userActions';
import { VehicleFeature } from 'components/sections/inventoryItem/details/vehicleDetails';
import { hasFeatureFlag } from 'utils/featureFlagUtils';
import { isAutogradeEnabled } from 'utils/inventoryItemUtils';
import { isVerified, isVerifiedExtensiveVehicleCondition } from 'utils/auctionItemUtils';
import { t } from 'utils/intlUtils';
import { vehicleFeatures } from 'components/sections/inventoryItem/details/inventoryItemDetails';

import style from './conditionReport.scss';

interface Props {
  /** Callback function to handle feature click event. */
  handleFeatureClicked: (featureType: string, inventoryItemDetails?: LooseObject) => void;
  /** Callback function to open add/modify modal. */
  handleOpenAddModifyModal: (feature: VehicleFeature) => void;
  /** The inventory item details. */
  inventoryItemDetails: InventoryItem;
  /** True when item is sold as is. */
  isAsIs?: boolean;
  /** True when item is assured. */
  isAssured?: boolean;
  /** True when inventory item details are editable. */
  isUpdatable: boolean;
  /** Function invoked on user action. */
  trackUserAction?: (userAction: UserAction, event?: MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => void;
  /** The current user. */
  user: User;
}

const ConditionReport = ({
  handleFeatureClicked,
  handleOpenAddModifyModal,
  inventoryItemDetails,
  isAsIs,
  isAssured,
  isUpdatable,
  trackUserAction,
  user,
}: Props) => {
  const {
    autocheckExceptions,
    autocheckExceptionsReceived,
    captureType,
    conditionReport,
    damagePhotos,
    id,
    incompleteFieldMessages,
    location,
    paintCondition,
    tireCondition,
    vehicleBuyerShield,
    verifiedBy,
  } = inventoryItemDetails;

  const activeDeclarations = conditionReport && conditionReport.activeDeclarations;
  const autogradeEnabled = isAutogradeEnabled(inventoryItemDetails);
  const hasNoTitle = (activeDeclarations || []).findIndex((d) => d.id === 'transferableTitleNotOnHand') > -1;
  const isNonRunner = (activeDeclarations || []).findIndex((d) => d.id === 'nonRunnerRequiresTow') > -1;
  const verified = isVerified(captureType);

  const isRequiredField = useCallback(
    (field: string) => {
      return !!incompleteFieldMessages?.find((message) => message?.field === field);
    },
    [incompleteFieldMessages]
  );

  const onScoreExtensiveButtonClick = useCallback(
    (event) => {
      handleFeatureClicked('SCORE', inventoryItemDetails);
      trackUserAction?.(UserAction.VEHICLE_SCORE_CLICK, event);
    },
    [handleFeatureClicked, inventoryItemDetails, trackUserAction]
  );

  const onScoreButtonClick = useCallback(
    (event) => {
      const { score: vehicleDeclarationsScore } = vehicleFeatures;
      isUpdatable
        ? handleOpenAddModifyModal(vehicleDeclarationsScore)
        : handleFeatureClicked('SCORE', inventoryItemDetails);
      trackUserAction?.(UserAction.VEHICLE_SCORE_CLICK, event);
    },
    [isUpdatable, handleOpenAddModifyModal, handleFeatureClicked, inventoryItemDetails, trackUserAction]
  );

  const renderScoreButton = useCallback(() => {
    if (autogradeEnabled) {
      return <AutoGradeScoreButton inventoryItem={inventoryItemDetails} />;
    }

    if (isVerifiedExtensiveVehicleCondition(inventoryItemDetails?.captureType)) {
      return <ScoreExtensiveButton inventoryItem={inventoryItemDetails} onClick={onScoreExtensiveButtonClick} />;
    }

    return <ScoreButton inventoryItem={inventoryItemDetails} isUpdatable={isUpdatable} onClick={onScoreButtonClick} />;
  }, [autogradeEnabled, inventoryItemDetails, isUpdatable, onScoreButtonClick, onScoreExtensiveButtonClick]);

  return (
    <div>
      {hasNoTitle && (
        <NotificationBanner primaryGlyphType="warning">
          {t('seller_does_not_have_transferable_title')}
        </NotificationBanner>
      )}

      {isNonRunner && (
        <NotificationBanner primaryGlyphType="warning">{t('vehicle_non_runner_requires_tow')}</NotificationBanner>
      )}

      {isAsIs && <NotificationBanner primaryGlyphType="warning">{t('vehicle_is_being_sold_as_is')}</NotificationBanner>}

      {vehicleBuyerShield && (
        <NotificationBanner
          primaryGlyphType="buyerShield"
          secondaryGlyphURL="https://eblock.com/en-us#buyershield"
          theme="black"
        >
          {t('buyer_shield_eligible_message')}
        </NotificationBanner>
      )}

      {isAssured && (
        <NotificationBanner primaryGlyphType="assured" theme="gold">
          {t('vehicle_is_assured_message')}
        </NotificationBanner>
      )}

      {verified && (
        <NotificationBanner primaryGlyphType="verified" theme="green">
          {verifiedBy}
        </NotificationBanner>
      )}
      <div className={classnames(style.crButtonGroup)}>
        <div className={style.crButtonContainer}>
          {location?.countryCode === 'US' && (
            <AutoCheckButton
              autoCheckExceptions={autocheckExceptions?.filter(Boolean)}
              autoCheckExceptionsReceived={!!autocheckExceptionsReceived}
              onClick={(event) => trackUserAction?.(UserAction.AUTOCHECK_CLICK, event)}
            />
          )}
          {hasFeatureFlag(FeatureFlag.CARFAX_MVDA_SUPPORT) ? (
            <CarfaxButton
              countryCode={inventoryItemDetails.location?.countryCode}
              handleOpenAddModifyModal={handleOpenAddModifyModal}
              inventoryItemId={id}
              isUpdatable={isUpdatable}
              trackUserAction={trackUserAction}
              vehicleConditionReport={inventoryItemDetails.conditionReport}
              vin={inventoryItemDetails.vin || undefined}
            />
          ) : (
            <CarfaxButtonLegacy
              countryCode={inventoryItemDetails.location?.countryCode}
              handleOpenAddModifyModal={handleOpenAddModifyModal}
              isUpdatable={isUpdatable}
              onClick={(event) => trackUserAction?.(UserAction.CARFAX_CLICK, event)}
              user={user}
              vehicleConditionReport={inventoryItemDetails.conditionReport}
              vin={inventoryItemDetails.vin || undefined}
            />
          )}
        </div>

        <div className={style.crButtonContainer}>{renderScoreButton()}</div>

        {!autogradeEnabled && (
          <div className={style.crButtonContainer}>
            <DamagePhotosButton
              damagePhotos={damagePhotos}
              handleFeatureClicked={handleFeatureClicked}
              handleOpenAddModifyModal={handleOpenAddModifyModal}
              isUpdatable={isUpdatable}
              onClick={(event) => trackUserAction?.(UserAction.VDP_DAMAGE_PHOTOS_CLICK, event)}
            />
          </div>
        )}

        {!verified && (tireCondition || isUpdatable) && (
          <div className={style.crButtonContainer}>
            <NonVerifiedTireButton
              captureType={captureType}
              handleFeatureClicked={handleFeatureClicked}
              handleOpenAddModifyModal={handleOpenAddModifyModal}
              isUpdatable={isUpdatable}
              onClick={(event) => trackUserAction?.(UserAction.TIRES_CLICK, event)}
              tireCondition={tireCondition}
            />
          </div>
        )}

        {verified && (tireCondition || paintCondition) && (
          <div className={style.crButtonContainer}>
            {!autogradeEnabled && (paintCondition || isUpdatable) && (
              <div className={style.crButtonSmContainer}>
                <PaintButton
                  handleFeatureClicked={handleFeatureClicked}
                  inventoryItemDetails={inventoryItemDetails}
                  isRequiredField={isRequiredField}
                  onClick={(event) => trackUserAction?.(UserAction.VDP_PAINT_CLICK, event)}
                />
              </div>
            )}
            {(tireCondition || isUpdatable) && (
              <div className={classnames(!autogradeEnabled && style.crButtonSmContainer)}>
                <VerifiedTireButton
                  captureType={captureType}
                  handleFeatureClicked={handleFeatureClicked}
                  handleOpenAddModifyModal={handleOpenAddModifyModal}
                  isAutogradeEnabled={autogradeEnabled}
                  isRequiredField={isRequiredField}
                  isUpdatable={isUpdatable}
                  onClick={(event) => trackUserAction?.(UserAction.TIRES_CLICK, event)}
                  tireCondition={tireCondition}
                />
              </div>
            )}
          </div>
        )}
      </div>

      <ScoresRow inventoryItem={inventoryItemDetails} onClick={handleFeatureClicked} />

      <TireDetails captureType={captureType} tireCondition={tireCondition} />

      {!autogradeEnabled && <PaintConditionDetails paintCondition={paintCondition} />}

      <BlueDriverScanData
        inventoryItem={inventoryItemDetails}
        onObdiiClick={(event) => trackUserAction?.(UserAction.OBDII_CLICK, event)}
        onSmogClick={(event) => trackUserAction?.(UserAction.SMOG_READINESS_CLICK, event)}
      />
    </div>
  );
};

export default ConditionReport;
