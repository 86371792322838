import { MouseEventHandler, useCallback, useMemo, useState } from 'react';

import InventoryItem from 'constants/inventoryItem';
import ObdiiSlideOut from 'components/sections/inventoryItem/details/conditionReport/blueDriverScanData/obdiiSlideOut';
import SmogSlideOut from 'components/sections/inventoryItem/details/conditionReport/blueDriverScanData/smogSlideOut';
import {
  BlueDriverCodeScanEvent,
  BlueDriverSmogScanEvent,
  VehicleCaptureType,
} from 'store/shared/api/graph/interfaces/types';
import { OBDIIButton, SmogButton } from './blueDriverButtons';
import { getBlueDriverScanData } from 'store/shared/api/graph/queries/blueDriverScanData';
import { isVerified } from 'utils/auctionItemUtils';

import style from './blueDriverScanData.scss';

enum SlideOutType {
  OBDII,
  SMOG,
}

interface Props {
  /** The inventory item details. */
  inventoryItem: InventoryItem;
  /** Function invoked when OBDII button clicked. */
  onObdiiClick?: MouseEventHandler<HTMLButtonElement>;
  /** Function invoked when Smog button clicked. */
  onSmogClick?: MouseEventHandler<HTMLButtonElement>;
}

const BlueDriverScanData = ({ inventoryItem, onObdiiClick, onSmogClick }: Props) => {
  const { blueDriverScanData } = inventoryItem;
  const [codeScanEvents, setCodeScanEvents] = useState<BlueDriverCodeScanEvent[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [openSlideOutType, setOpenSlideOutType] = useState<SlideOutType>();
  const [smogScanEvent, setSmogScanEvent] = useState<BlueDriverSmogScanEvent>();

  /**
   * Fetch blue driver scan data.
   */
  const fetchScanData = useCallback(() => {
    setIsLoading(true);
    getBlueDriverScanData({ inventoryItemId: inventoryItem.id })
      ?.then((response) => {
        setCodeScanEvents(
          response?.data?.data?.inventoryItem?.blueDriverScanData?.blueDriverCodeScanEvents?.filter(Boolean) || []
        );
        setSmogScanEvent(response?.data?.data?.inventoryItem?.blueDriverScanData?.blueDriverSmogScanEvent || undefined);
      })
      ?.finally(() => setIsLoading(false));
  }, [inventoryItem.id]);

  /**
   * Open slideOut and fetch scan data.
   */
  const onOpenSlideOut = useCallback(
    (slideOutType: SlideOutType) => {
      if (openSlideOutType !== slideOutType) {
        setOpenSlideOutType(slideOutType);
        fetchScanData();
      }
    },
    [fetchScanData, openSlideOutType]
  );

  /**
   * Close slideOut only requested slideOut matches the currently open one.
   */
  const onCloseSlideOut = useCallback(
    (slideOutType: SlideOutType) => {
      if (slideOutType === openSlideOutType) {
        setOpenSlideOutType(undefined);
      }
    },
    [openSlideOutType]
  );

  /**
   * Open OBDII slideOut
   */
  const onObdiiButtonClick = useCallback(
    (event) => {
      onOpenSlideOut(SlideOutType.OBDII);
      onObdiiClick?.(event);
    },
    [onObdiiClick, onOpenSlideOut]
  );

  /**
   * Open Smog slideOut
   */
  const onSmogButtonClick = useCallback(
    (event) => {
      onOpenSlideOut(SlideOutType.SMOG);
      onSmogClick?.(event);
    },
    [onOpenSlideOut, onSmogClick]
  );

  /**
   * Display Smog button when USA verified and scan has been completed.
   */
  const displaySmogButton = useMemo(() => {
    return (
      [
        VehicleCaptureType.VERIFIED_AUTOGRADE_CAPTURE,
        VehicleCaptureType.VERIFIED_EXTENSIVE_VEHICLE_CONDITION_CAPTURE,
        VehicleCaptureType.VERIFIED_EXTERNAL_AUTOGRADE_CAPTURE,
      ].includes(inventoryItem?.captureType) &&
      blueDriverScanData?.smogScanCompleted &&
      blueDriverScanData?.blueDriverSmogScanEvent?.result
    );
  }, [
    blueDriverScanData?.blueDriverSmogScanEvent?.result,
    blueDriverScanData?.smogScanCompleted,
    inventoryItem?.captureType,
  ]);

  /**
   * Display OBDII button when USA or Canadian verified and scan has been completed.
   */
  const displayObdiiButton = useMemo(() => {
    return isVerified(inventoryItem?.captureType) && blueDriverScanData?.codeScanCompleted;
  }, [blueDriverScanData?.codeScanCompleted, inventoryItem?.captureType]);

  if (!displaySmogButton && !displayObdiiButton) {
    // Prevent rendering if neither scans are available
    return null;
  }

  return (
    <>
      <div className={style.container}>
        {displaySmogButton && <SmogButton inventoryItem={inventoryItem} onClick={onSmogButtonClick} />}
        {displaySmogButton && displayObdiiButton && <div className={style.divider} />}
        {displayObdiiButton && <OBDIIButton inventoryItem={inventoryItem} onClick={onObdiiButtonClick} />}
      </div>
      <SmogSlideOut
        isLoading={isLoading}
        isOpen={openSlideOutType === SlideOutType.SMOG}
        onClose={() => onCloseSlideOut(SlideOutType.SMOG)}
        smogScanEvent={smogScanEvent}
      />
      <ObdiiSlideOut
        codeScanEvents={codeScanEvents}
        isLoading={isLoading}
        isOpen={openSlideOutType === SlideOutType.OBDII}
        onClose={() => onCloseSlideOut(SlideOutType.OBDII)}
      />
    </>
  );
};

export default BlueDriverScanData;
