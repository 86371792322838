import classnames from 'classnames';
import moment from 'moment';
import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';

import AcceptOffer from 'components/sections/auctionItem/details/status/statusRows/offerRow/acceptOffer/acceptOffer';
import CounterOffer from 'components/sections/auctionItem/details/status/statusRows/offerRow/counterOffer/counterOffer';
import DeclineOffer from 'components/sections/auctionItem/details/status/statusRows/offerRow/declineOffer/declineOffer';
import Divider from 'components/sections/admin/liveLanes/shared/divider';
import OfferExpiryTimer from 'components/sections/auctionItem/details/status/statusRows/offerRow/offerExpiryTimer/offerExpiryTimer';
import OfferStatusLabel, {
  ExtendedIfBidStatus,
  OfferStatus,
} from 'components/sections/auctionItem/details/status/statusRows/offerRow/offerStatusLabel/offerStatusLabel';
import SlideOut from 'components/ui/slideOuts/slideOut';
import { AppState } from 'store/configureStore';
import { Company, IfBidStatus, TimedOffer } from 'store/shared/api/graph/interfaces/types';
import { DateFormat } from 'constants/enums/dateAndTime';
import { UserProps } from 'store/user/userModels';
import { formatDate } from 'utils/dateUtils';
import { representsCompany } from 'utils/userUtils';
import { t } from 'utils/intlUtils';

import styleVariables from 'styles/variables.scss';
import style from './offerRowSlideOut.scss';

interface Props {
  /** True when the slide out is open. */
  isOpen: boolean;
  /** List of offer */
  offers: TimedOffer[][];
  /** Function invoked on slide out close. */
  onClose: () => void;
}

const OfferRowSlideOut = ({ isOpen, offers, onClose }: Props) => {
  const user = useSelector<AppState, UserProps>((state) => state.app.user);
  const sellerCompany = useSelector<AppState, Pick<Company, 'id' | 'name'>>(
    (state) => state.app.auctionItemDetails.details?.inventoryItem.company
  );
  const isAwaitingSellersResponse = useSelector<AppState, boolean>((state) => {
    const details = state.app.auctionItemDetails.details;
    return !details?.isMyItem && !!details?.timedOfferTimeline?.waitingOnThem;
  });
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);

  /**
   * Returns formatted offer heading
   */
  const getOfferHeading = useCallback(
    (companyName: string, offerStatus: OfferStatus, isCompanyRepresentative: boolean) => {
      switch (offerStatus) {
        case IfBidStatus.ACCEPTED:
          return isCompanyRepresentative
            ? t('if_bid_status_you_accepted')
            : t('if_bid_status_they_accepted', [companyName]);
        case IfBidStatus.DECLINED:
          return isCompanyRepresentative
            ? t('if_bid_status_you_declined')
            : t('if_bid_status_they_declined', [companyName]);
        case IfBidStatus.EXPIRED:
          return t('if_bid_status_they_offer_expired', [companyName]);
        case ExtendedIfBidStatus.COUNTERED:
          return isCompanyRepresentative
            ? t('if_bid_status_you_countered')
            : t('if_bid_status_they_countered', [companyName]);
        default:
          return isCompanyRepresentative
            ? t('if_bid_status_you_offered')
            : t('if_bid_status_they_offered', [companyName]);
      }
    },
    []
  );

  return (
    <SlideOut
      contentInnerClassName={style.container}
      data-testid="offer-slideout"
      isClickOutsideDisabled={isDialogOpen}
      isOpen={isOpen}
      onClose={onClose}
      title={t('offers')}
    >
      {offers.map((timedOffers) => {
        /**
         * The last offer of `timedOffers` array is always the buyer offer.
         */
        const buyerOffer = timedOffers[timedOffers.length - 1];
        const buyerCompany = buyerOffer.company;

        return (
          <ul key={buyerOffer.id} className={style.list}>
            {timedOffers.map(
              (
                {
                  id,
                  amount,
                  amount: { formattedAmountRounded },
                  comment,
                  company,
                  created,
                  createdBy,
                  expiry,
                  status,
                },
                index
              ) => {
                /**
                 * Check to see if the current Timed Offer entry belongs to a user who represents the company
                 */
                const isCompanyRepresentative = representsCompany(user, company.id);

                /**
                 * Used to detect the actionable company belongs to the seller or the buyer. This is crucial if
                 * user is a `Staff` and has no direct context if they are performing as a seller or a buyer
                 */
                const isNextActionUserSeller = company.id === buyerCompany.id;
                const actionableCompany = isNextActionUserSeller ? sellerCompany : buyerCompany;

                /**
                 * Check to see if the previous Timed Offer entry is in `INACTIVE` state, if `true` and
                 * current Timed Offer entry is not `EXPIRED, DECLINED or ACCEPTED` state, then this
                 * Timed Offer entry is considered a `COUNTERED` state.
                 */
                const previousOffer: TimedOffer | undefined = timedOffers[index + 1];
                const isPreviousOfferInactive = previousOffer?.status === IfBidStatus.INACTIVE;
                const isCounterOffer =
                  isPreviousOfferInactive &&
                  ![IfBidStatus.EXPIRED, IfBidStatus.DECLINED, IfBidStatus.ACCEPTED].includes(status);
                const offerStatus: OfferStatus = isCounterOffer ? ExtendedIfBidStatus.COUNTERED : status;

                return (
                  <li key={id} className={style.listItem}>
                    {!!expiry && status === IfBidStatus.ACTIVE && (
                      <div className={style.timer}>
                        <OfferExpiryTimer offerStatus={offerStatus} timeEnd={moment(expiry)} />
                      </div>
                    )}
                    <div className={style.offerContainer}>
                      <div className={style.offer}>
                        <div className={style.left}>
                          <div className={style.heading}>
                            {getOfferHeading(company.name, offerStatus, isCompanyRepresentative)}
                          </div>
                          {/**
                           * Only display `Awaiting Response` for Buyer and on the latest `timedOffer` entry.
                           */}
                          {isCompanyRepresentative && isAwaitingSellersResponse && index === 0 && (
                            <div className={style.awaitingResponse}>{t('awaiting_response')}</div>
                          )}
                          {!isCompanyRepresentative && (
                            <div className={style.user}>
                              {t('by_x', [[createdBy.firstName, createdBy.lastName].join(' ')])}
                            </div>
                          )}
                          <div className={style.created}>{formatDate(created, DateFormat.TIME_STAMP_FORMAT)}</div>
                        </div>
                        <div className={style.right}>
                          <div
                            className={classnames(
                              style.price,
                              [IfBidStatus.DECLINED, IfBidStatus.EXPIRED].includes(status) && style.strike
                            )}
                          >
                            {formattedAmountRounded}
                          </div>
                          <OfferStatusLabel offerStatus={offerStatus} />
                        </div>
                      </div>
                      {comment && (
                        <div className={style.comment}>
                          <span className={style.prefix}>{t('message')}:</span> {comment}
                        </div>
                      )}
                      {!isCompanyRepresentative && status === IfBidStatus.ACTIVE && (
                        <>
                          <Divider color={styleVariables.colorGrayLighter} />
                          <div className={style.actions} data-testid="action-buttons">
                            <AcceptOffer
                              acceptOfferCompany={actionableCompany}
                              isSeller={isNextActionUserSeller}
                              offeredAmount={amount}
                              offerId={id}
                              offeringCompany={company}
                              onIsOpenChange={setIsDialogOpen}
                            />
                            <CounterOffer
                              counterOfferCompany={actionableCompany}
                              isSeller={isNextActionUserSeller}
                              offeredAmount={amount}
                              offerId={id}
                              onIsOpenChange={setIsDialogOpen}
                            />
                            <DeclineOffer
                              companyDeclining={actionableCompany}
                              companyOffering={company}
                              isSeller={isNextActionUserSeller}
                              offeredAmount={amount}
                              offerId={id}
                              onIsOpenChange={setIsDialogOpen}
                            />
                          </div>
                        </>
                      )}
                    </div>
                  </li>
                );
              }
            )}
          </ul>
        );
      })}
    </SlideOut>
  );
};

export default OfferRowSlideOut;
