import { Route } from 'store/routing/routes';

export enum FacetGroup {
  ACCOUNTING_SYSTEM_ID = 'accountingSystemId',
  ARBITRATION_CANCELLED = 'arbitrationCancelled',
  ARBITRATION_ON_TIME = 'arbitrationOnTime',
  ARBITRATION_RESPONSIBLE_PARTY = 'arbitrationResponsibleParty',
  ARBITRATION_SPACER = 'arbitrationSpacer',
  ARBITRATION_TAGS = 'arbitrationTags',
  ARBITRATION_TRANSACTION_TYPE = 'arbitrationTransactionType',
  AUCTION = 'auction',
  AUCTION_ACCESS_STATUS = 'auctionAccessStatus',
  AUCTION_ITEM_END_TIME = 'auctionItemEndTime',
  AUCTION_LANE_TYPE = 'auctionLaneType',
  AUCTION_LOCATION_NAME = 'auctionLocationName',
  AUCTION_START_TIME = 'auctionStartTime',
  AUCTION_TIME_SLOT_LANE = 'auctionTimeSlotLane',
  AUCTION_TITLE = 'auctionTitle',
  BODY_TYPE = 'bodyType',
  BUYER = 'buyer',
  BUYER_REGIONAL_SALES_MANAGER = 'buyerRegionalSalesManager',
  BUYER_TERRITORY_SALES_MANAGER = 'buyerTerritorySalesManager',
  CARRIER = 'carrier',
  CHARGING_CABLE = 'chargingCable',
  CITY = 'city',
  CONSIGNER = 'consigner',
  COUNTRY = 'country',
  CREATED_BY = 'createdBy',
  DELIVERED_DATE = 'deliveredDate',
  DELIVERY_REGION = 'deliveryRegion',
  DESTINATION_CITY = 'destinationCity',
  DESTINATION_REGION = 'destinationRegion',
  DRIVE_TRAIN = 'driveTrain',
  ESTIMATED_DELIVERY_DATE = 'estimatedDeliveryDate',
  ESTIMATED_PICKUP_DATE = 'estimatedPickupDate',
  EXTERIOR_COLOR = 'exteriorColor',
  FILTER_BY = 'filterBy',
  FORMATS = 'formats',
  FUEL_TYPE = 'fuelType',
  HEADING = 'heading',
  IF_BID_CLAIM = 'ifBidClaim',
  IF_BID_CLAIMED_BY_USER_NAME = 'ifBidClaimedByUserName',
  LOCALE = 'locale',
  LOCATION_COUNTRY = 'locationCountry',
  MAKES = 'makes',
  MODELS = 'models',
  OMVIC_STATUS = 'omvicStatus',
  OPTION = 'option',
  PAYMENT_STATUS = 'paymentStatus',
  PHONE_STATUS = 'phoneStatus',
  PICKUP_CITY = 'pickupCity',
  PICKUP_REGION = 'pickupRegion',
  REGION = 'region',
  SALES_MANAGER = 'salesManager',
  SALES_REP = 'salesRep',
  SALES_REP_ID = 'salesRepId',
  SALE_LIGHTS = 'saleLights',
  SELLER = 'seller',
  SELLER_REGION = 'sellerRegion',
  SELLER_REGIONAL_SALES_MANAGER = 'sellerRegionalSalesManager',
  SELLER_TERRITORY_SALES_MANAGER = 'sellerTerritorySalesManager',
  SHIPMENT_MODE = 'shipmentMode',
  STATUS = 'status',
  SUB_MODELS = 'subModels',
  SUB_TYPE = 'subType',
  TIME_ZONE = 'timeZone',
  TITLE = 'title',
  TRANSACTION_TYPE = 'transactionType',
  TRANSMISSION = 'transmission',
  TYPE = 'type',
  VALID_TITLE = 'validTitle',
  VERIFIED = 'verified',
  VISIBLE_REGION = 'visibleRegion',
  YEAR_GTE = 'yearGTE',
  YEAR_LTE = 'yearLTE',
}

/**
 * Facet groups used for each route.
 */
export const facetNamesByRoute: { [key: string]: FacetGroup[] } = {
  [Route.BUY_TIMED_AUCTION]: [
    FacetGroup.AUCTION_ITEM_END_TIME,
    FacetGroup.AUCTION_TITLE,
    FacetGroup.AUCTION_LOCATION_NAME,
    FacetGroup.BODY_TYPE,
    FacetGroup.CONSIGNER,
    FacetGroup.DRIVE_TRAIN,
    FacetGroup.EXTERIOR_COLOR,
    FacetGroup.FILTER_BY,
    FacetGroup.FORMATS,
    FacetGroup.FUEL_TYPE,
    FacetGroup.MAKES,
    FacetGroup.MODELS,
    FacetGroup.OPTION,
    FacetGroup.REGION,
    FacetGroup.SALE_LIGHTS,
    FacetGroup.SUB_MODELS,
    FacetGroup.TRANSMISSION,
  ],
  [Route.BUY_RUN_LIST]: [
    FacetGroup.AUCTION_TITLE,
    FacetGroup.AUCTION_TIME_SLOT_LANE,
    FacetGroup.AUCTION_LANE_TYPE,
    FacetGroup.AUCTION_LOCATION_NAME,
    FacetGroup.AUCTION_START_TIME,
    FacetGroup.BODY_TYPE,
    FacetGroup.CONSIGNER,
    FacetGroup.DRIVE_TRAIN,
    FacetGroup.EXTERIOR_COLOR,
    FacetGroup.FILTER_BY,
    FacetGroup.FUEL_TYPE,
    FacetGroup.MAKES,
    FacetGroup.MODELS,
    FacetGroup.OPTION,
    FacetGroup.REGION,
    FacetGroup.SALE_LIGHTS,
    FacetGroup.SUB_MODELS,
    FacetGroup.TRANSMISSION,
  ],
  [Route.BUY_BUY_NOW]: [
    FacetGroup.AUCTION_TITLE,
    FacetGroup.AUCTION_TIME_SLOT_LANE,
    FacetGroup.AUCTION_LANE_TYPE,
    FacetGroup.AUCTION_LOCATION_NAME,
    FacetGroup.AUCTION_START_TIME,
    FacetGroup.BODY_TYPE,
    FacetGroup.CONSIGNER,
    FacetGroup.DRIVE_TRAIN,
    FacetGroup.EXTERIOR_COLOR,
    FacetGroup.FILTER_BY,
    FacetGroup.FUEL_TYPE,
    FacetGroup.MAKES,
    FacetGroup.MODELS,
    FacetGroup.OPTION,
    FacetGroup.REGION,
    FacetGroup.SALE_LIGHTS,
    FacetGroup.SUB_MODELS,
    FacetGroup.TRANSMISSION,
  ],
  [Route.SELL_PARKED]: [
    FacetGroup.AUCTION,
    FacetGroup.AUCTION_LOCATION_NAME,
    FacetGroup.BODY_TYPE,
    FacetGroup.CONSIGNER,
    FacetGroup.DRIVE_TRAIN,
    FacetGroup.EXTERIOR_COLOR,
    FacetGroup.FORMATS,
    FacetGroup.FUEL_TYPE,
    FacetGroup.MAKES,
    FacetGroup.MODELS,
    FacetGroup.OPTION,
    FacetGroup.REGION,
    FacetGroup.STATUS,
    FacetGroup.SUB_MODELS,
    FacetGroup.TRANSMISSION,
  ],
  [Route.PENDING_IN_IF_BID]: [
    FacetGroup.AUCTION_TITLE,
    FacetGroup.AUCTION_LOCATION_NAME,
    FacetGroup.BODY_TYPE,
    FacetGroup.CONSIGNER,
    FacetGroup.DRIVE_TRAIN,
    FacetGroup.EXTERIOR_COLOR,
    FacetGroup.FILTER_BY,
    FacetGroup.FORMATS,
    FacetGroup.FUEL_TYPE,
    FacetGroup.IF_BID_CLAIM,
    FacetGroup.MAKES,
    FacetGroup.MODELS,
    FacetGroup.OPTION,
    FacetGroup.REGION,
    FacetGroup.SUB_MODELS,
    FacetGroup.TRANSMISSION,
  ],
  [Route.PENDING_DELIVERY]: [
    FacetGroup.AUCTION_TITLE,
    FacetGroup.AUCTION_LOCATION_NAME,
    FacetGroup.BODY_TYPE,
    FacetGroup.CONSIGNER,
    FacetGroup.DRIVE_TRAIN,
    FacetGroup.EXTERIOR_COLOR,
    FacetGroup.FILTER_BY,
    FacetGroup.FORMATS,
    FacetGroup.FUEL_TYPE,
    FacetGroup.MAKES,
    FacetGroup.MODELS,
    FacetGroup.OPTION,
    FacetGroup.REGION,
    FacetGroup.SUB_MODELS,
    FacetGroup.TRANSACTION_TYPE,
    FacetGroup.TRANSMISSION,
  ],
  [Route.PENDING_AWAITING_CHECKOUT]: [
    FacetGroup.AUCTION_TITLE,
    FacetGroup.AUCTION_LOCATION_NAME,
    FacetGroup.BODY_TYPE,
    FacetGroup.CONSIGNER,
    FacetGroup.DRIVE_TRAIN,
    FacetGroup.EXTERIOR_COLOR,
    FacetGroup.FILTER_BY,
    FacetGroup.FORMATS,
    FacetGroup.FUEL_TYPE,
    FacetGroup.MAKES,
    FacetGroup.MODELS,
    FacetGroup.OPTION,
    FacetGroup.REGION,
    FacetGroup.SUB_MODELS,
    FacetGroup.TRANSACTION_TYPE,
    FacetGroup.TRANSMISSION,
  ],
  [Route.FINISHED_SOLD]: [
    FacetGroup.AUCTION_TITLE,
    FacetGroup.AUCTION_LOCATION_NAME,
    FacetGroup.BODY_TYPE,
    FacetGroup.CONSIGNER,
    FacetGroup.DRIVE_TRAIN,
    FacetGroup.EXTERIOR_COLOR,
    FacetGroup.FILTER_BY,
    FacetGroup.FORMATS,
    FacetGroup.FUEL_TYPE,
    FacetGroup.MAKES,
    FacetGroup.MODELS,
    FacetGroup.OPTION,
    FacetGroup.REGION,
    FacetGroup.SUB_MODELS,
    FacetGroup.TRANSMISSION,
  ],
  [Route.FINISHED_NO_SALE]: [
    FacetGroup.AUCTION_TITLE,
    FacetGroup.AUCTION_LOCATION_NAME,
    FacetGroup.BODY_TYPE,
    FacetGroup.CONSIGNER,
    FacetGroup.DRIVE_TRAIN,
    FacetGroup.EXTERIOR_COLOR,
    FacetGroup.FILTER_BY,
    FacetGroup.FORMATS,
    FacetGroup.FUEL_TYPE,
    FacetGroup.MAKES,
    FacetGroup.MODELS,
    FacetGroup.OPTION,
    FacetGroup.REGION,
    FacetGroup.SUB_MODELS,
    FacetGroup.TRANSMISSION,
  ],
  [Route.FINISHED_CANCELLED]: [
    FacetGroup.AUCTION_TITLE,
    FacetGroup.AUCTION_LOCATION_NAME,
    FacetGroup.BODY_TYPE,
    FacetGroup.CONSIGNER,
    FacetGroup.DRIVE_TRAIN,
    FacetGroup.EXTERIOR_COLOR,
    FacetGroup.FILTER_BY,
    FacetGroup.FORMATS,
    FacetGroup.FUEL_TYPE,
    FacetGroup.MAKES,
    FacetGroup.MODELS,
    FacetGroup.OPTION,
    FacetGroup.REGION,
    FacetGroup.SUB_MODELS,
    FacetGroup.TRANSMISSION,
  ],
  [Route.MY_BLOCK_BUYER]: [
    FacetGroup.AUCTION_TITLE,
    FacetGroup.AUCTION_LOCATION_NAME,
    FacetGroup.BODY_TYPE,
    FacetGroup.CONSIGNER,
    FacetGroup.FORMATS,
    FacetGroup.REGION,
    FacetGroup.STATUS,
  ],
  [Route.MY_BLOCK_SELLER]: [
    FacetGroup.AUCTION_TITLE,
    FacetGroup.AUCTION_LOCATION_NAME,
    FacetGroup.BODY_TYPE,
    FacetGroup.CONSIGNER,
    FacetGroup.FORMATS,
    FacetGroup.REGION,
    FacetGroup.STATUS,
  ],
  [Route.TRANSPORT_JOBS]: [
    FacetGroup.AUCTION_TITLE,
    FacetGroup.BUYER,
    FacetGroup.CARRIER,
    FacetGroup.CREATED_BY,
    FacetGroup.DELIVERED_DATE,
    FacetGroup.DESTINATION_CITY,
    FacetGroup.DESTINATION_REGION,
    FacetGroup.ESTIMATED_DELIVERY_DATE,
    FacetGroup.ESTIMATED_PICKUP_DATE,
    FacetGroup.FILTER_BY,
    FacetGroup.PICKUP_CITY,
    FacetGroup.PICKUP_REGION,
    FacetGroup.SELLER,
    FacetGroup.STATUS,
  ],
  [Route.TRANSPORT_CARRIERS]: [FacetGroup.DELIVERY_REGION, FacetGroup.SHIPMENT_MODE, FacetGroup.STATUS],
  [Route.ADMIN_COMPANIES]: [
    FacetGroup.AUCTION,
    FacetGroup.CITY,
    FacetGroup.COUNTRY,
    FacetGroup.FILTER_BY,
    FacetGroup.REGION,
    FacetGroup.SALES_REP,
    FacetGroup.SALES_REP_ID,
    FacetGroup.STATUS,
    FacetGroup.SUB_TYPE,
    FacetGroup.TYPE,
    FacetGroup.VISIBLE_REGION,
  ],
  [Route.ADMIN_USERS]: [
    FacetGroup.AUCTION_ACCESS_STATUS,
    FacetGroup.CITY,
    FacetGroup.COUNTRY,
    FacetGroup.LOCALE,
    FacetGroup.OMVIC_STATUS,
    FacetGroup.PHONE_STATUS,
    FacetGroup.REGION,
    FacetGroup.STATUS,
    FacetGroup.TIME_ZONE,
    FacetGroup.TITLE,
  ],
  [Route.ADMIN_ARBITRATIONS]: [
    FacetGroup.ARBITRATION_CANCELLED,
    FacetGroup.ARBITRATION_ON_TIME,
    FacetGroup.ARBITRATION_RESPONSIBLE_PARTY,
    FacetGroup.ARBITRATION_SPACER,
    FacetGroup.ARBITRATION_TAGS,
    FacetGroup.ARBITRATION_TRANSACTION_TYPE,
    FacetGroup.BUYER,
    FacetGroup.FILTER_BY,
    FacetGroup.HEADING,
    FacetGroup.SELLER,
  ],
  [Route.ADMIN_EVENTS_LANE_MANAGEMENT]: [
    FacetGroup.AUCTION_LANE_TYPE,
    FacetGroup.AUCTION_LOCATION_NAME,
    FacetGroup.BODY_TYPE,
    FacetGroup.CONSIGNER,
    FacetGroup.DRIVE_TRAIN,
    FacetGroup.EXTERIOR_COLOR,
    FacetGroup.FILTER_BY,
    FacetGroup.FORMATS,
    FacetGroup.FUEL_TYPE,
    FacetGroup.MAKES,
    FacetGroup.MODELS,
    FacetGroup.OPTION,
    FacetGroup.REGION,
    FacetGroup.SALE_LIGHTS,
    FacetGroup.SUB_MODELS,
    FacetGroup.TRANSMISSION,
  ],
};
