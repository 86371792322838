import { useMemo } from 'react';

import Loading from 'components/ui/loading/loading';
import ObdiiCodesTooltip from 'components/sections/inventoryItem/details/conditionReport/blueDriverScanData/obdiiCodesTooltip';
import ObdiiScanListItem from 'components/sections/inventoryItem/details/conditionReport/blueDriverScanData/obdiiScanListItem';
import SlideOut from 'components/ui/slideOuts/slideOut';
import { BlueDriverCodeScanEvent, BlueDriverCodeScanEventType } from 'store/shared/api/graph/interfaces/types';
import { SlideOutComponentProps } from 'components/sections/inventoryItem/details/slideOut/slideOut';
import { t } from 'utils/intlUtils';

import style from './obdiiSlideOut.scss';

interface Props extends SlideOutComponentProps {
  /** The code scan events. */
  codeScanEvents: BlueDriverCodeScanEvent[];
  /** Whether the list is loading or not. */
  isLoading?: boolean;
  /** Whether the slide out is open or not. */
  isOpen?: boolean;
  /** Callback function triggered when slide out closes. */
  onClose?: () => void;
}

const obdiiCodesTooltip = <ObdiiCodesTooltip />;

const ObdiiSlideOut = ({ codeScanEvents, isLoading, isOpen, onClose }: Props) => {
  const scanDetails = useMemo(() => {
    if (!codeScanEvents || isLoading) {
      return null;
    }

    const scanEventsList = Object.values(BlueDriverCodeScanEventType).map((scanEventType) => {
      return codeScanEvents
        .filter((event) => event.type === scanEventType)
        .map((scanEvent) => (
          <li key={`${scanEvent?.type}-${scanEvent?.code}`} className={style.listItem}>
            <ObdiiScanListItem scanEvent={scanEvent} />
          </li>
        ));
    });

    return (
      <div className={style.container}>
        <ul className={style.list}>{scanEventsList}</ul>
      </div>
    );
  }, [codeScanEvents, isLoading]);

  return (
    <SlideOut headerActions={obdiiCodesTooltip} isOpen={isOpen} onClose={onClose} title={t('obdii')}>
      <div className={style.scanDetails}>{scanDetails}</div>
      <Loading hasFullWidth isLoading={isLoading} isModalTransparent />
    </SlideOut>
  );
};

export default ObdiiSlideOut;
