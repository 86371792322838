import { connect, ConnectedProps } from 'react-redux';

import removeGlyph from 'glyphs/operationIcons/circle-cancel.svg';

import BaseClass from 'components/ui/shared/base';
import Dialog from './dialog';
import OperationButton from 'components/sections/inventoryItem/operations/operationButton';
import { AppDispatch } from 'store/configureStore';
import { ErrorMessages } from 'constants/errors';
import { MutationauctionItemPullFromAuctionArgs } from 'store/shared/api/graph/interfaces/types';
import { UserAction } from 'logging/analytics/events/userActions';
import { getErrors } from 'utils/apiUtils';
import { processPullFromAuction } from 'store/auctionItemDetails/auctionItemDetailsActions';
import { t } from 'utils/intlUtils';

const dispatchConnect = (dispatch: AppDispatch) => ({
  /** Function used to pull auction item from auction. */
  pullFromAuction: (options: MutationauctionItemPullFromAuctionArgs) => processPullFromAuction(options, dispatch),
});

const connector = connect(null, dispatchConnect);

interface Props extends ConnectedProps<typeof connector> {
  /** The id of the auction item */
  auctionItemId: string;
  /** Function invoked when pullFromAuction has completed. */
  onDone?: (value?: boolean) => void;
  /** Callback function to track user actions. */
  trackUserAction?: (action: UserAction) => void;
}

interface State {
  /** Validation errors. */
  errorMessages: ErrorMessages | null;
  /** True when dialog is open. */
  isOpen: boolean;
  /** True when the form is submitting. */
  isSubmitting: boolean;
}

class PullFromAuction extends BaseClass<Props, State> {
  static defaultProps = {
    onDone: () => {},
  };

  constructor(props: Props) {
    super(props);

    this.state = { errorMessages: null, isOpen: false, isSubmitting: false };
  }

  onSubmit = (shouldSubmit: boolean) => {
    const { auctionItemId, pullFromAuction, onDone, trackUserAction } = this.props;

    if (shouldSubmit) {
      trackUserAction?.(UserAction.VDP_PULL_FROM_AUCTION_CONFIRM_CLICK);
      this.setState({ isSubmitting: true });
      pullFromAuction({ auctionItemId })
        .then(() => {
          this.setState({ errorMessages: null, isOpen: false, isSubmitting: false });
          onDone?.(true);
        })
        .catch((error) => {
          const errorMessages = getErrors(error).map((err) => err.message);
          this.setState({ errorMessages, isSubmitting: false });
        });
    } else {
      this.setState({ errorMessages: null, isOpen: false });
      onDone?.(false);
    }
  };

  render() {
    const { trackUserAction } = this.props;
    return (
      <>
        <OperationButton
          dataTestId="pullFromAuction"
          glyph={removeGlyph}
          onClick={() => {
            trackUserAction?.(UserAction.VDP_PULL_FROM_AUCTION_CLICK);
            this.setState({ isOpen: true });
          }}
          theme="red"
        >
          {t('pull_from_auction')}
        </OperationButton>
        <Dialog {...this.state} onSubmit={this.onSubmit} />
      </>
    );
  }
}

export default connector(PullFromAuction);
