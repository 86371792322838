import BaseClass from 'components/ui/shared/base';
import Button from 'components/ui/shared/button';
import Checkbox from 'forms/shared/checkbox';
import ConfirmDialog from 'components/ui/shared/dialogs/confirmDialog';
import InputText from 'forms/shared/inputText';
import Link from 'components/ui/shared/link';
import { AmountType, DifferenceAmount } from 'store/shared/api/graph/interfaces/types';
import { ErrorMessages } from 'constants/errors';
import { Route } from 'store/routing/routes';
import { RouterProps, withRouter } from 'constants/reactRouter';
import { t } from 'utils/intlUtils';

import style from './dialog.scss';

interface Props extends RouterProps {
  /** The seller's company id. */
  companyId?: string;
  /** The default holdback amount. */
  defaultHoldback?: DifferenceAmount;
  /** Validation errors. */
  errorMessages?: ErrorMessages;
  /** The holdback amount. */
  holdback?: DifferenceAmount;
  /** True when active checkbox is checked. */
  isActive: boolean;
  /** True when the dialog is open. */
  isOpen: boolean;
  /** True when form is being submitted. */
  isSubmitting: boolean;
  /** Function invoked to update the holdback amount. */
  onSubmit: (shouldSubmit: boolean, isActive?: boolean, type?: AmountType, amount?: number) => Promise<void>;
}

interface State {
  /** The current state of the holdback checkbox. */
  isActive: boolean;

  /** The amount type. */
  type: AmountType | null;
}

class Dialog extends BaseClass<Props, State> {
  private amount: HTMLInputElement | null = null;

  constructor(props) {
    super(props);

    this.state = {
      isActive: props.isActive,
      type: null,
    };
  }

  shouldComponentUpdate(nextProps) {
    const { isOpen } = this.props;
    const { isOpen: willBeOpen } = nextProps;

    if (isOpen !== willBeOpen) {
      this.setState({
        type: null,
      });
    }
    return true;
  }

  componentDidUpdate(prevProps) {
    const { isActive: isActivePrev } = prevProps;
    const { isActive } = this.props;

    if (isActivePrev !== isActive) {
      this.setState({ isActive });
    }
  }

  getAmount = (type: AmountType, value: string | undefined) => {
    if (!value) {
      return undefined;
    }

    const amountFloat = parseFloat(value);
    return type === AmountType.AMOUNT ? amountFloat : amountFloat / 100;
  };

  setHoldback = (shouldSubmit) => {
    const { type: typeState, isActive } = this.state;
    const { onSubmit, holdback, defaultHoldback } = this.props;

    if (shouldSubmit) {
      const type = typeState || (holdback || {}).type || (defaultHoldback || {}).type || AmountType.AMOUNT;
      const amount = this.getAmount(type, this.amount?.value);
      onSubmit(true, isActive, type, amount);
    } else {
      onSubmit(false);
    }
  };

  changeType = (type) => {
    const { type: typeState } = this.state;

    if (type !== typeState) {
      this.setState({ type });
    }
  };

  render() {
    const { type: typeState } = this.state;
    const { companyId, defaultHoldback, errorMessages, holdback, isActive, isOpen, isSubmitting, location } =
      this.props;

    const hasError = errorMessages && errorMessages.length > 0;
    const redirectUrl = [location?.pathname, location?.search].join('');

    const type = typeState || (holdback || {}).type || (defaultHoldback || {}).type || 'AMOUNT';
    const amount = (holdback || {}).amount || (defaultHoldback || {}).amount;
    const displayAmountDefault = defaultHoldback
      ? String(
          Math.round(100 * defaultHoldback.amount * (defaultHoldback.type === AmountType.PERCENTAGE ? 100 : 1)) / 100
        )
      : '';
    const displayAmount = !amount
      ? ''
      : String(Math.round(100 * amount * (type === AmountType.PERCENTAGE ? 100 : 1)) / 100);

    return (
      <ConfirmDialog
        actionLabel={t('set_holdback')}
        actionProgress={isSubmitting}
        isOpen={isOpen}
        onConfirm={this.setHoldback}
        theme="blue"
        title={t('holdback')}
      >
        {defaultHoldback ? (
          <p className={style.dealershipSetting} data-testid="default-holdback">
            {t('default_holdback_message')}{' '}
            <em>
              {defaultHoldback.type === AmountType.AMOUNT && '$'}
              {displayAmountDefault}
              {defaultHoldback.type === AmountType.PERCENTAGE && '%'}
            </em>
            .
          </p>
        ) : (
          <p className={style.dealershipSetting} data-testid="set-default-holdback">
            {t('default_holdback_not_set_message')}{' '}
            <Link
              className={style.link}
              to={{
                ...location,
                pathname: `${Route.ACCOUNT}/companies`,
                query: { holdback: 'true', id: companyId, onComplete: encodeURIComponent(redirectUrl) },
              }}
            >
              {t('set_it_now')}
            </Link>
            .
          </p>
        )}

        <Checkbox
          checked={isActive}
          className={style.checkboxContainer}
          id="holdbackActive"
          name="isActive"
          onChange={(e) => this.setState({ isActive: e.target.checked })}
          text={t('holdback_active')}
        />

        <div className={style.inputContainer}>
          <InputText
            className={style.inputText}
            defaultValue={displayAmount}
            name="amount"
            placeholder={`${type === AmountType.AMOUNT ? '$' : '%'}   ${t('enter_holdback')}`}
            reference={(input: HTMLInputElement) => {
              this.amount = input;
            }}
            textClassName={style.input}
            theme={hasError ? 'error' : undefined}
            type="text"
          />
          <Button
            className={style.dollar}
            onClick={() => this.changeType('AMOUNT')}
            theme={type === AmountType.AMOUNT ? 'blue' : 'gray-outline'}
          >
            $
          </Button>
          <Button
            className={style.percent}
            onClick={() => this.changeType('PERCENTAGE')}
            theme={type === AmountType.PERCENTAGE ? 'blue' : 'gray-outline'}
          >
            %
          </Button>
        </div>

        {errorMessages &&
          errorMessages.map((errorMessage, index) => (
            <p key={`error${index}`} className={style.errorMessage}>
              {errorMessage.message}
            </p>
          ))}
      </ConfirmDialog>
    );
  }
}

export default withRouter(Dialog);
