import { useMemo } from 'react';

import InteractiveText from 'components/ui/shared/interactiveText';
import { t } from 'utils/intlUtils';

import style from './footerSupportText.scss';

interface Props {
  isShortForm?: boolean;
}

const FooterSupportText = ({ isShortForm }: Props) => {
  const text = useMemo(() => {
    return t(isShortForm ? 'contact_us_message_web' : 'registration_info_message');
  }, [isShortForm]);

  return (
    <div className={style.registrationInfo}>
      <InteractiveText linkClassName={style.link}>{text}</InteractiveText>
    </div>
  );
};

export default FooterSupportText;
