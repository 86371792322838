/**
 * A list of active flags
 * When referencing flag names in the code, the flag names should be included in this enum.
 */
export enum FeatureFlag {
  ACCOUNT_VERIFICATION = 'account-verification',
  CARFAX_MVDA_SUPPORT = 'carfax-mvda-support',
  NUMERIC_COMPANY_ID = 'numeric-company-id',

  // Unlike the above, the following is only used for testing purposes
  ZZZ_TEST_EXAMPLE = 'zzz-test-example',
}
