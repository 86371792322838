import { createAction } from 'redux-actions';

import { AppDispatch } from 'store/configureStore';
import { Locale, setLocale } from 'utils/intlUtils';
import { MutationuserResetPasswordArgs, UserCreateAuctionAccessInput } from 'store/shared/api/graph/interfaces/types';
import { authenticate, resetPassword, userCreateAuctionAccess, userLogoutSession } from './authApi';
import { cacheAuth, clearAuthCache, getAuthToken, setActiveSessionCookie } from 'utils/authUtils';
import { clearRoutingTarget } from '../routing/routingActions';
import { getUserData, userCleared, processSetAndPersistBiddingAsSelection } from 'store/user/userActions';
import { notesCleared } from 'store/notes/notesActions';
import { setUserSessionExpiryTime } from 'containers/notifications/userSessionExpiry/userSessionExpiryHelpers';

export const isLoading = createAction('AUTH_SET_IS_LOADING');
export const isNotLoading = createAction('AUTH_UNSET_IS_LOADING');
export const isUpdating = createAction('AUTH_SET_IS_UPDATING');
export const isNotUpdating = createAction('AUTH_UNSET_IS_UPDATING');
export const isAuctionAccessMode = createAction('AUTH_SET_IS_AUCTION_ACCESS_MODE');
export const authError = createAction('AUTH_ERROR');
export const authErrorCleared = createAction('AUTH_ERROR_CLEARED');
export const login = createAction('USER_LOGIN');
export const logout = createAction('USER_LOGOUT');

export const logoutAndDeleteToken = (dispatch, cb?) => {
  if (getAuthToken()) {
    // Clear the user's session if an active one is present, then clear user session expiry field in localStorage
    userLogoutSession()?.then(() => setUserSessionExpiryTime(null));
  } else {
    // Clear user session expiry field in localStorage
    setUserSessionExpiryTime(null);
  }

  dispatch(isLoading());
  clearAuthCache();
  dispatch(clearRoutingTarget());
  dispatch(logout());
  dispatch(userCleared());
  dispatch(notesCleared());
  setActiveSessionCookie(false);

  if (typeof cb === 'function') {
    cb();
  }
};

export const processTryLogin = (dispatch) => {
  const token = getAuthToken();

  return new Promise<void>((resolve, reject) => {
    if (token) {
      dispatch(login());
      getUserData(dispatch)
        .then((response) => {
          // Update current locale
          setLocale(response?.user?.locale as Locale);

          // Set active session cookie
          setActiveSessionCookie(response?.user?.id);

          return resolve();
        })
        .catch((error) => reject(error));
    } else {
      resolve();
    }
  });
};

export const processUserLogin = (dispatch, data, cb) => {
  dispatch(isLoading());
  return authenticate(data).then((response) => {
    // Cache session token, set current locale, and dispatch successful login
    const userAuthenticate = response?.data?.data?.userAuthenticate;
    const user = userAuthenticate?.user;
    cacheAuth(userAuthenticate?.token);
    setLocale(user?.locale as Locale);
    dispatch(login());

    setActiveSessionCookie(user?.id);

    // Fetch user info
    getUserData(dispatch).then(() => {
      processSetAndPersistBiddingAsSelection(null, dispatch);

      if (typeof cb === 'function') {
        cb();
      }
    });
  });
};

export const processResetPassword = (options: MutationuserResetPasswordArgs, dispatch) => {
  return resetPassword(options);
};

export const processUserCreateAuctionAccess = (options: UserCreateAuctionAccessInput, dispatch: AppDispatch) => {
  return userCreateAuctionAccess(options).then((response) => {
    // Cache session token, set current locale, and dispatch successful login
    const user = response?.data?.data?.userCreateAuctionAccess;
    cacheAuth(user?.authenticationToken);
    setLocale(user?.locale as Locale);
    dispatch(login());

    return response;
  });
};
