import classnames from 'classnames';
import { HTMLAttributes, ReactNode } from 'react';

import Button, { ButtonProps, ButtonTheme } from 'components/ui/shared/button';
import Sprite, { Glyph } from 'components/ui/shared/sprite';

import style from './operationButton.scss';

interface OperationButtonProps extends ButtonProps {
  /** The child elements to be rendered. */
  children?: ReactNode;
  /** CSS styling to overwrite default style. */
  className?: string;
  /** The rendered svg image. */
  glyph?: Glyph;
  /** CSS styling to overwrite default glyph style. */
  glyphClassName?: string;
  /** Function to render badge component. */
  renderBadge?: (options: { className: string }) => void;
  /** The button theme. */
  theme?: ButtonTheme;
}

const OperationButton = ({
  children,
  className,
  glyph,
  glyphClassName,
  renderBadge,
  theme = 'blue',
  ...props
}: OperationButtonProps) => {
  return (
    <Button className={classnames(style.button, glyph && style.buttonGlyph, className)} theme={theme} {...props}>
      <>
        {glyph && <Sprite className={classnames(style.glyph, glyphClassName)} glyph={glyph} />}
        {renderBadge && renderBadge({ className: style.badge })}
        {children}
      </>
    </Button>
  );
};

interface OperationButtonContainerProps extends Omit<HTMLAttributes<HTMLDivElement>, 'className' | 'children'> {
  /** The child elements to be rendered. */
  children: ReactNode;
  /** CSS styling to overwrite default style. */
  className?: string;
}

export const OperationButtonContainer = ({ children, className, ...props }: OperationButtonContainerProps) => (
  <div className={classnames(style.operationsContainer, className)} {...props}>
    {children}
  </div>
);

export default OperationButton;
