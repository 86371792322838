import { useMemo } from 'react';
import classNames from 'classnames';

import { BlueDriverCodeScanEvent, BlueDriverCodeScanEventType } from 'store/shared/api/graph/interfaces/types';
import { Circle } from 'components/ui/shared/shapes';
import { t } from 'utils/intlUtils';

import style from './obdiiScanListItem.scss';

interface Props {
  /** The code scan event. */
  scanEvent: BlueDriverCodeScanEvent;
}

const ObdiiScanListItem = ({ scanEvent }: Props) => {
  const codeTypeStyle = useMemo(
    () => `eventType-${scanEvent.type && BlueDriverCodeScanEventType[scanEvent.type]?.toLowerCase()}`,
    [scanEvent.type]
  );

  return (
    <div data-testid="obdii-list-item">
      <div className={style.codeRow}>
        <div>{scanEvent.code}</div>
        <div className={classNames(style.eventType, style[codeTypeStyle])}>{scanEvent.type}</div>
      </div>
      <div className={style.systemRow}>
        <div>{t('system')}</div>
        <Circle className={style.dot} height="4px" width="4px" />
        <div>{scanEvent.system}</div>
      </div>
      <div className={style.description}>{scanEvent.description}</div>
    </div>
  );
};

export default ObdiiScanListItem;
