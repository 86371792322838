import { ReactNode } from 'react';
import classnames from 'classnames';

import exclamationGlyph from 'glyphs/exclamation.svg';
import closeGlyph from 'glyphs/plus-skinny.svg';

import Button from 'components/ui/shared/button';
import Sprite from 'components/ui/shared/sprite';
import { SecondaryTitle, SecondaryTitleLight } from 'layouts/list/listItemLayout';

import style from './banner.scss';

export enum BannerTheme {
  DEFAULT = 'default',
  SUCCESS = 'success',
  WARNING = 'warning',
  CRITICAL = 'critical',
}

interface BannerProps {
  children?: ReactNode | ReactNode[];
  className?: string;
  onClose?: () => void;
  theme: BannerTheme;
  title: string | null;
}

const Banner = ({ children, className, onClose, theme = BannerTheme.DEFAULT, title }: BannerProps) => {
  return (
    <div
      className={classnames(style.container, style[`container-theme-${theme}`], className)}
      data-testid="bannerContainer"
    >
      <Sprite className={classnames(style.icon, style[`icon-theme-${theme}`])} glyph={exclamationGlyph} />
      <div>
        <SecondaryTitle className={style.title}>{title}</SecondaryTitle>
        <SecondaryTitleLight className={style.content}>{children}</SecondaryTitleLight>
      </div>
      {onClose && (
        <Button className={style.closeButton} dataTestId="banner-closeButton" onClick={onClose} theme="none">
          <Sprite className={classnames(style.closeIcon, style[`closeIcon-theme-${theme}`])} glyph={closeGlyph} />
        </Button>
      )}
    </div>
  );
};

export default Banner;
