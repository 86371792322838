import classnames from 'classnames';

import Button, { ButtonProps as AuthButtonProps } from 'components/ui/shared/button';
import Link, { LinkComponentProps as AuthLinkProps } from 'components/ui/shared/link';

import style from './auth.scss';

export const SubmitButton = ({ children, className, ...props }: AuthButtonProps) => (
  <Button className={classnames(style.submitButton, className)} type="submit" {...props}>
    {children}
  </Button>
);

export const WhiteAuthButton = ({ children, className, ...props }: AuthButtonProps) => (
  <Button className={classnames(style.whiteAuthButton, className)} theme="none" {...props}>
    {children}
  </Button>
);

export const WhiteAuthLink = ({ children, className, to, ...props }: AuthLinkProps) => (
  <Link className={classnames(style.link, className)} theme="button" to={to} {...props}>
    {children}
  </Link>
);

export const TransparentAuthButton = ({ children, className, ...props }: AuthButtonProps) => (
  <Button className={classnames(style.link, style.linkTransparent, className)} theme="none" {...props}>
    {children}
  </Button>
);
