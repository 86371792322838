import axios from 'axios';
import { gql } from '@apollo/client';

import ApiRequest, { ApiResponse } from 'store/shared/api/apiRequest';
import { ApolloContextHeaders, client } from 'store/shared/apollo/apolloClient';
import {
  AuctionAccessValidateUserResponse,
  AuctionConnection,
  AuthResponse,
  ForgotPasswordMessage,
  MutationuserForgotPasswordArgs,
  MutationuserResetPasswordArgs,
  QueryuserValidateAuctionAccessArgs,
  RequestedInviteSuccess,
  User,
  UserCreateAuctionAccessInput,
} from 'store/shared/api/graph/interfaces/types';
import { deviceInfoHeaders } from 'utils/deviceInfoUtils';
import { getGraphBaseURL } from 'utils/apiUtils';
import { localeStorage } from 'utils/intlUtils';

const authApi = axios.create({
  baseURL: `${getGraphBaseURL()}`,
  headers: {
    'Content-Type': 'application/json',
    'Channel-Type': 'WEB',
    'EBlock-Accept-Language': localeStorage.get(),
    'Accept-Language': localeStorage.get(),
    'Access-Control-Max-Age': '600', // Preflight requests are cached for 10 minutes
    'Cache-Control': 'no-cache',
    Expires: 'Mon, 26 Jul 1997 05:00:00 GMT', // All cached responses expired in 1997.
    Pragma: 'no-cache',
    'X-Frame-Options': 'DENY',
    ...deviceInfoHeaders,
  },
});

export const authenticate = ({
  email,
  password,
}): ApiResponse<{ userAuthenticate: Pick<AuthResponse, 'token' | 'user'> }> => {
  return authApi.request({
    url: '/graphql',
    method: 'POST',
    data: {
      query: `
        mutation($email: String!, $password: String!) {
          userAuthenticate(email: $email, password: $password) {
            token
            user {
              id
              locale
            }
          }
        }
      `,
      variables: {
        email,
        password,
      },
    },
  });
};

export const forgotPassword = (email: MutationuserForgotPasswordArgs['email']) =>
  client.mutate<{ userForgotPassword: ForgotPasswordMessage }>({
    mutation: gql`
      mutation ($email: String!) {
        userForgotPassword(email: $email) {
          message
        }
      }
    `,
    variables: {
      email,
    },
    context: { headers: ApolloContextHeaders.NO_AUTH_REQUIRED },
  });

export const resetPassword = ({
  token,
  password,
}: MutationuserResetPasswordArgs): ApiResponse<{ result: { token: string } }> =>
  authApi.request({
    url: '/graphql',
    method: 'POST',
    data: {
      query: `
      mutation($password: String!, $token: String!) {
        result: userResetPassword(password: $password, token: $token) {
          token: authenticationToken
        }
      }
    `,
      variables: {
        password,
        token,
      },
    },
  });

export const requestUserInvite = (options): ApiResponse<{ requestedInviteCreate: RequestedInviteSuccess }> =>
  authApi.request({
    url: '/graphql',
    method: 'POST',
    data: {
      operationName: 'requestUserInvite',
      query: `
      mutation($name: String, $dealerName: String, $email: String, $phone: String) {
        requestedInviteCreate(name: $name, dealerName: $dealerName, email: $email, phone: $phone) {
          message
        }
      }
    `,
      variables: {
        name: options.name,
        dealerName: options.dealerName,
        email: options.email,
        phone: options.phone,
      },
    },
  });

export const userValidateAuctionAccess = (
  options: QueryuserValidateAuctionAccessArgs
): ApiResponse<{ userValidateAuctionAccess: AuctionAccessValidateUserResponse }> =>
  authApi.request({
    url: '/graphql',
    method: 'POST',
    data: {
      query: `
      query($auctionAccessId: Int!, $auctionAccessLast4GovId: String!) {
        userValidateAuctionAccess(auctionAccessId: $auctionAccessId, auctionAccessLast4GovId: $auctionAccessLast4GovId) {
          auctionAccessId
          companies {
            companyAuctionAccessId
            legalName
            name
          }
          firstName
          issues {
            blockingRegistration
            issueId
            issueName
            message
          }
          lastName
          validationStatus
        }
      }
    `,
      variables: options,
    },
  });

export const userCreateAuctionAccess = (
  options: UserCreateAuctionAccessInput
): ApiResponse<{ userCreateAuctionAccess: User }> =>
  authApi.request({
    url: '/graphql',
    method: 'POST',
    data: {
      query: `
      mutation($form: UserCreateAuctionAccessInput!) {
        userCreateAuctionAccess(form: $form) {
          id
          authenticationToken
          locale
        }
      }
    `,
      variables: { form: options },
    },
  });

/**
 * Used to populate a list of available auctions in the
 * Choose Auctions step within the Auction Access Registration.
 */
export const getAuctionsList = () =>
  ApiRequest<{ auctionConnection: AuctionConnection }>({
    data: {
      operationName: 'getAuctionsList',
      query: `
      {
        auctionConnection(first:50) {
          edges {
            node {
              id
              title
            }
          }
        }
      }
    `,
    },
  });

export const authenticateEmployee = ({
  code = null,
}): ApiResponse<{ authenticateEmployee: Pick<User, 'authenticationToken' | 'id' | 'status'> }> =>
  authApi.request({
    url: '/graphql',
    method: 'POST',
    data: {
      query: `
        mutation($code: String!) {
          employeeAuthenticate(code: $code) {
            id
            authenticationToken
            status
          }
        }
      `,
      variables: {
        code,
      },
    },
  });

export const authenticatePipeline = ({
  code = null,
}): ApiResponse<{ authenticatePipeline: Pick<User, 'authenticationToken' | 'id' | 'status'> }> =>
  authApi.request({
    url: '/graphql',
    method: 'POST',
    data: {
      query: `
        mutation($code: String!) {
          authenticatePipeline(code: $code) {
            id
            authenticationToken
            status
          }
        }
      `,
      variables: {
        code,
      },
    },
  });

export const userLogoutSession = () =>
  ApiRequest<{ userLogoutSession: Pick<User, 'id'> }>({
    data: {
      query: `
        mutation {
          userLogoutSession {
            id
          }
        }
      `,
    },
  });
