import ServerRecord, { ServerRecordOf } from 'store/shared/models/serverRecord';
import { getAuthToken } from 'utils/authUtils';

interface AuthModel {
  /** Whether is auction access mode or not. */
  isAuctionAccessMode: boolean;

  /** Whether is logged in or not. */
  isLoggedIn: boolean;
}

export type AuthProps = ServerRecordOf<AuthModel>;

/**
 * Initial state of model
 */
export const InitialState = ServerRecord<AuthModel>({
  isAuctionAccessMode: false,
  isLoggedIn: !!getAuthToken(),
});
