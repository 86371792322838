import { connect, ConnectedProps } from 'react-redux';

import AuctionItem from 'constants/auctionItem';
import BaseClass from 'components/ui/shared/base';
import Dialog from './dialog';
import OperationButton from 'components/sections/inventoryItem/operations/operationButton';
import { AppDispatch } from 'store/configureStore';
import { AsIsBadge } from 'components/sections/inventoryItem/details/inventoryItemBadges';
import { ErrorMessages } from 'constants/errors';
import { MutationauctionItemSetAsIsArgs } from 'store/shared/api/graph/interfaces/types';
import { UserAction } from 'logging/analytics/events/userActions';
import { getErrors } from 'utils/apiUtils';
import { processSetAsIs } from 'store/auctionItemDetails/auctionItemDetailsActions';
import { t } from 'utils/intlUtils';
import { trackUserActionWithAuctionItemAttributes } from 'utils/analyticsUtils';

const dispatchConnect = (dispatch: AppDispatch) => ({
  setAsIs: (options: MutationauctionItemSetAsIsArgs) => processSetAsIs(options, dispatch),
});

const connector = connect(undefined, dispatchConnect);

interface Props extends ConnectedProps<typeof connector> {
  /** The auction item details. */
  auctionItem: AuctionItem;
}

interface State {
  /** Validation Errors. */
  errorMessages: ErrorMessages | undefined;
  /** True when the dialog is open. */
  isOpen: boolean;
  /** True when form is being submitted. */
  isSubmitting: boolean;
}

class SetAsIs extends BaseClass<Props, State> {
  constructor(props) {
    super(props);

    this.state = { isOpen: false, errorMessages: undefined, isSubmitting: false };
  }

  onSubmit = (shouldSubmit, options) => {
    const { auctionItem, setAsIs } = this.props;

    if (shouldSubmit) {
      trackUserActionWithAuctionItemAttributes(UserAction.VDP_SET_AS_IS_CONFIRM_CLICK, auctionItem);
      this.setState({ isSubmitting: true });
      setAsIs(options)
        .then(() => this.setState({ errorMessages: null, isOpen: false, isSubmitting: false }))
        .catch((error) => {
          const errorMessages = getErrors(error).map(({ name, message }) => ({ name, message }));
          this.setState({ errorMessages, isSubmitting: false });
        });
    } else {
      this.setState({ errorMessages: null, isOpen: false });
    }
  };

  render() {
    const { auctionItem } = this.props;
    const { isOpen } = this.state;

    return (
      <>
        <OperationButton
          onClick={() => {
            trackUserActionWithAuctionItemAttributes(UserAction.VDP_SET_AS_IS_CLICK, auctionItem);
            this.setState({ isOpen: true });
          }}
          renderBadge={(props) => <AsIsBadge showTooltip={false} theme="white" {...props} />}
          theme="red"
        >
          {t('set_as_is')}
        </OperationButton>
        {isOpen && <Dialog auctionItem={auctionItem} onSubmit={this.onSubmit} {...this.state} />}
      </>
    );
  }
}

export default connector(SetAsIs);
