import BaseClass from 'components/ui/shared/base';
import ConfirmDialog from 'components/ui/shared/dialogs/confirmDialog';
import { ErrorMessages } from 'constants/errors';
import { t } from 'utils/intlUtils';

import style from './dialog.scss';

interface Props {
  /** Validation errors. */
  errorMessages?: ErrorMessages;

  /** True when dialog is open. */
  isOpen: boolean;

  /** True when form is submitting. */
  isSubmitting: boolean;

  /** Function invoked on confirm click. */
  onSubmit: (shouldSubmit: boolean) => void;
}

class Dialog extends BaseClass<Props> {
  render() {
    const { isOpen, onSubmit, errorMessages, isSubmitting } = this.props;

    return (
      <ConfirmDialog
        actionLabel={t('vehicle_has_arrived')}
        actionProgress={isSubmitting}
        isOpen={isOpen}
        onConfirm={onSubmit}
        theme="green"
        title={t('vehicle_delivered')}
      >
        {t('vehicle_delivered_dialog_message')}
        {errorMessages &&
          errorMessages.map((errorMessage, index) => (
            <p key={`error${index}`} className={style.errorMessage}>
              {errorMessage.message}
            </p>
          ))}
      </ConfirmDialog>
    );
  }
}

export default Dialog;
