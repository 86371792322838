import classnames from 'classnames';
import { Fragment, useCallback, useMemo } from 'react';

import InputText, { InputTextProps } from 'forms/shared/inputText';
import { AccountSection } from 'layouts/accountLayouts/accountLayouts';
import { AddModifyVehicleProps } from 'store/inventoryItem/addModify/addModifyModels';
import { VehicleTireConditionInput } from 'store/shared/api/graph/interfaces/types';
import { t } from 'utils/intlUtils';

import style from './tireCondition.scss';

enum TirePosition {
  FRONT = 'Front',
  REAR = 'Rear',
}

enum TireSide {
  DRIVER = 'driver',
  PASSENGER = 'passenger',
}

interface TireConditionInputRowConfig {
  /** Tire position. */
  position: TirePosition;
  /** Tire side. */
  side: TireSide;
}

const tireConditionInputRowConfig: TireConditionInputRowConfig[] = [
  { side: TireSide.PASSENGER, position: TirePosition.FRONT },
  { side: TireSide.DRIVER, position: TirePosition.FRONT },
  { side: TireSide.DRIVER, position: TirePosition.REAR },
  { side: TireSide.PASSENGER, position: TirePosition.REAR },
];

interface TireConditionInputConfig extends InputTextProps {
  /** Callback function to get input value. */
  getValue?: (value: string | number, index?: number) => string | number;
}

interface TireConditionInputGroupConfig {
  /** Additional class name. */
  className?: string;
  /** Input field name. */
  field: string;
  /** Set of inputs. */
  inputs: TireConditionInputConfig[];
  /** Input group title. */
  title: string;
}

interface Props {
  /** Callback function to clear error. */
  clearError: () => void;
  /** Whether there are errors or not. */
  hasError?: boolean;
  /** Callback function to set vehicle information. */
  setVehicle: (options: AddModifyVehicleProps) => void;
  /** Tire condition information. */
  tireCondition?: AddModifyVehicleProps['tireCondition'];
}

export const TireCondition = ({ clearError, hasError, tireCondition, setVehicle }: Props) => {
  /**
   * Memoized tire condition inputs.
   */
  const tireConditionInputsConfig = useMemo(() => {
    return tireConditionInputRowConfig.map(({ side, position }): TireConditionInputGroupConfig[] => {
      const brandValueKey: keyof VehicleTireConditionInput = `${side}${position}Brand`;
      const sizeValueKey: keyof VehicleTireConditionInput = `${side}${position}Size`;
      const treadValueKey: keyof VehicleTireConditionInput = `${side}${position}Tread`;
      const brand = tireCondition?.[brandValueKey];
      const size = tireCondition?.[sizeValueKey]?.split('/').map((item) => item.trim()) || [];
      const tread = tireCondition?.[treadValueKey];

      const getTireSizeValue = (index: number): ((value: number | string) => string) => {
        const newTireSize = [...size];
        return (value: number | string): string => {
          newTireSize[index] = value.toString();
          return newTireSize.join(' / ');
        };
      };

      return [
        {
          title: t(`${side}_${position}_tire`.toLowerCase()),
          field: brandValueKey,
          inputs: [
            {
              defaultValue: brand || undefined,
              name: brandValueKey,
              placeholder: t('brand'),
            },
          ],
        },
        {
          title: t('tire_size'),
          className: style.tireSizeColumn,
          field: sizeValueKey,
          inputs: [
            {
              defaultValue: size[0] && Number(size[0]),
              getValue: getTireSizeValue(0),
              name: `${sizeValueKey}-1`,
              type: 'number',
            },
            {
              defaultValue: size[1] && Number(size[1]),
              getValue: getTireSizeValue(1),
              name: `${sizeValueKey}-2`,
              type: 'number',
            },
            {
              defaultValue: size[2] && Number(size[2]),
              getValue: getTireSizeValue(2),
              name: `${sizeValueKey}-3`,
              type: 'number',
            },
          ],
        },
        {
          title: t('tread'),
          className: style.tireTreadColumn,
          field: treadValueKey,
          inputs: [
            {
              defaultValue: tread || undefined,
              getValue: (value: number | string): number => {
                return Number(value);
              },
              name: `${treadValueKey}-1`,
              type: 'number',
            },
            { defaultValue: 32, disabled: true, name: `${treadValueKey}-2`, type: 'number' },
          ],
        },
      ];
    });
  }, [tireCondition]);

  /**
   * Updates vehicle information on tire condition change.
   */
  const onTireConditionChange = useCallback(
    (field: string, getValue?: TireConditionInputConfig['getValue']) => (value: number | string) => {
      setVehicle({ tireCondition: { ...(tireCondition || {}), [field]: getValue ? getValue(value) : value } });
      if (hasError) {
        clearError();
      }
    },
    [clearError, hasError, setVehicle, tireCondition]
  );

  return (
    <>
      {tireConditionInputsConfig.map((inputRow, rowIndex) => (
        <div
          key={`tire-condition-input-row-${rowIndex}`}
          className={classnames(style.segmentLayout, style.tireConditionInputsLayout, style.segment)}
        >
          {inputRow.map((inputGroup) => (
            <AccountSection
              key={inputGroup.field}
              className={classnames(style.column, inputGroup.className)}
              title={inputGroup.title}
              titleClass={style.title}
            >
              <div className={style.section}>
                <div className={style.inputGroup}>
                  {inputGroup.inputs.map(({ getValue, ...inputProps }, inputIndex) => {
                    return (
                      <Fragment key={inputProps.name}>
                        {inputIndex > 0 && '/'}
                        <InputText
                          {...inputProps}
                          className={classnames(style.inputClass, inputProps.type && style[inputProps.type])}
                          dataTestId={inputProps.name}
                          onChange={
                            (!inputProps.disabled && onTireConditionChange(inputGroup.field, getValue)) || undefined
                          }
                        />
                      </Fragment>
                    );
                  })}
                </div>
              </div>
            </AccountSection>
          ))}
        </div>
      ))}
    </>
  );
};

export default TireCondition;
