import { MouseEventHandler, useMemo } from 'react';

import Button from 'components/ui/shared/button';
import InventoryItem from 'constants/inventoryItem';
import { BlueDriverSmogScanEventResult } from 'store/shared/api/graph/interfaces/types';
import { Chevron } from 'components/ui/shared/chevrons';
import { t } from 'utils/intlUtils';

import style from './blueDriverButtons.scss';

interface Props {
  /** The inventory item details. */
  inventoryItem: InventoryItem;
  /** Function invoked when button clicked. */
  onClick: MouseEventHandler<HTMLButtonElement>;
}

export const SmogButton = ({ inventoryItem, onClick }: Props) => {
  const smogResult =
    inventoryItem?.blueDriverScanData?.blueDriverSmogScanEvent?.result ?? BlueDriverSmogScanEventResult.Fail;

  const smogResultColour = useMemo(() => {
    switch (smogResult) {
      case BlueDriverSmogScanEventResult.Fail:
        return style.red;

      case BlueDriverSmogScanEventResult.Pass:
        return style.green;

      case BlueDriverSmogScanEventResult.Warning:
        return style.yellow;

      default:
        return undefined;
    }
  }, [smogResult]);

  return (
    <Button className={style.button} onClick={onClick} theme="none">
      <div className={style.crRowTitle}>{t('smog_readiness')}</div>
      <div className={style.crRowContent}>
        <div className={smogResultColour}>{smogResult}</div>
        <Chevron />
      </div>
    </Button>
  );
};

export const OBDIIButton = ({ inventoryItem, onClick }: Props) => {
  const blueDriverCodeScanEventsCount = inventoryItem?.blueDriverScanData?.blueDriverCodeScanEventsCount ?? 0;

  return (
    <Button className={style.button} onClick={onClick} theme="none">
      <div className={style.crRowTitle}>{t('obdii')}</div>
      <div className={style.crRowContent}>
        {blueDriverCodeScanEventsCount === 0 ? t('no_codes_found') : t('codes_found')}
        <Chevron />
      </div>
    </Button>
  );
};
