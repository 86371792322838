import { ConnectedProps, connect } from 'react-redux';
import { useCallback, useState } from 'react';

import AuctionItem from 'constants/auctionItem';
import ModifyPricingDialog from 'components/sections/auctionItem/operations/modifyPricing/dialog';
import StatusRow from 'components/sections/myBlock/list/status/statusRow';
import { AppDispatch, AppState } from 'store/configureStore';
import {
  AuctionItemFormat,
  AuctionItemStatus,
  AuctionItemUpdateInput,
  AuctionTimeSlotStatus,
} from 'store/shared/api/graph/interfaces/types';
import { ErrorMessages } from 'constants/errors';
import { isAuctionStaff } from 'utils/userUtils';
import { isAuctionFormat } from 'utils/auctionItemUtils';
import { myBlockUpdateAuctionItem } from 'store/myBlock/list/myBlockActions';
import { onApiError } from 'utils/apiUtils';
import { processAuctionItemUpdate } from 'store/auctionItemDetails/auctionItemDetailsActions';
import { t } from 'utils/intlUtils';

const stateConnect = (state: AppState) => ({
  /** True if the logged-in user represents auction staff */
  isStaffUser: isAuctionStaff(state.app.user),
});

const dispatchConnect = (dispatch: AppDispatch) => ({
  /** Callback function to update auction item. */
  itemUpdate: (options: AuctionItemUpdateInput) => processAuctionItemUpdate(options, dispatch),
  /** Callback function to update list item */
  updateListItem: (auctionItem) => dispatch(myBlockUpdateAuctionItem(auctionItem)),
});

const connector = connect(stateConnect, dispatchConnect);

interface Props extends ConnectedProps<typeof connector> {
  /** The auction item details. */
  auctionItem: AuctionItem;
  /** ID used for testing. */
  dataTestId?: string;
}

const StatusRowReserve = ({ auctionItem, dataTestId, isStaffUser, itemUpdate, updateListItem }: Props) => {
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [errorMessages, setErrorMessages] = useState<ErrorMessages | null>(null);

  const { status, format, bidTimeline } = auctionItem;
  const timeSlotStatus = auctionItem?.auctionTimeSlot?.status;
  const bids = bidTimeline?.list ? bidTimeline.list?.filter(Boolean) : [];
  const isAuthorized = isStaffUser;
  const isUpcoming =
    status === AuctionItemStatus.UPCOMING &&
    isAuctionFormat(format) &&
    timeSlotStatus === AuctionTimeSlotStatus.WAITING;
  const isTimedFormat = !bids.length && [AuctionItemFormat.GROUNDED, AuctionItemFormat.APPRAISAL].includes(format);
  const isTimedStatus = isTimedFormat && [AuctionItemStatus.UPCOMING, AuctionItemStatus.LIVE].includes(status);
  const isEditable = isAuthorized && (isUpcoming || (isTimedFormat && isTimedStatus));

  /**
   * Submit form
   */
  const onSubmit = useCallback(
    (shouldSubmit: boolean, options: AuctionItemUpdateInput) => {
      if (shouldSubmit) {
        setErrorMessages(null);
        setIsSubmitting(true);

        itemUpdate(options)
          ?.then((item) => {
            updateListItem(item);
            setIsDialogOpen(false);
          })
          ?.catch((error) => onApiError(error, setErrorMessages))
          ?.finally(() => setIsSubmitting(false));
        return;
      }

      setIsDialogOpen(false);
    },
    [itemUpdate, updateListItem]
  );

  return (
    <>
      <StatusRow
        dataTestId={dataTestId}
        onEdit={isEditable ? () => setIsDialogOpen(true) : undefined}
        subTitle={auctionItem?.reserve?.formattedAmount}
        title={t('reserve')}
      />
      <ModifyPricingDialog
        auctionItem={auctionItem}
        errorMessages={errorMessages}
        isOpen={isDialogOpen}
        isSubmitting={isSubmitting}
        onSubmit={onSubmit}
      />
    </>
  );
};

export default connector(StatusRowReserve);
