import ApiRequest from 'store/shared/api/apiRequest';
import {
  MutationuserAccountSendVerificationCodeArgs,
  MutationuserAccountVerifyArgs,
} from 'store/shared/api/graph/interfaces/types';

export const userAccountSendVerificationCode = (options: MutationuserAccountSendVerificationCodeArgs) =>
  ApiRequest<{ userAccountSendVerificationCode: boolean }>({
    data: {
      query: `
        mutation ($verifiedField: String!, $verificationType: UserAccountVerificationType!) {
          userAccountSendVerificationCode(verifiedField: $verifiedField, verificationType: $verificationType)
        }
      `,
      variables: options,
    },
  });

export const userAccountVerify = (options: MutationuserAccountVerifyArgs) =>
  ApiRequest<{ userAccountVerify: boolean }>({
    data: {
      query: `
        mutation ($code: String!, $verifiedField: String!, $verificationType: UserAccountVerificationType!) {
          userAccountVerify(code: $code, verifiedField: $verifiedField, verificationType: $verificationType) {
            id
          }
        }
      `,
      variables: options,
    },
  });
